import Vue from 'vue'
import VueRouter from 'vue-router'

import error from '@/views/error'
import Events from "@/views/events";
import Config from '@/views/config.vue'
import Security from "@/views/security";
import operators from '@/views/operators'
import tender from '@/views/tender'
import Users from '@/views/users.vue'
import statistics from "@/views/statistics";
import VueCookies from 'vue-cookies'
import store from "@/store";

Vue.use(VueRouter)

const COOKIES_TOKEN = VueCookies.get('admin') || null
if (COOKIES_TOKEN && COOKIES_TOKEN.access_token) store.commit('TokenM', COOKIES_TOKEN.access_token)
else store.commit('TokenM', null)

const routes = [
  {
    path: "*",
    name: 'Error',
    component: error,
    meta: {
      layout: 'admin',
      pageName: 'Ошибка!'
    }
  },
  {
    path: '/',
    name: 'Login',
    meta: {
      layout: 'login',
      pageName: 'Авторизация'
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      layout: 'admin',
      pageName: 'События'
    }
  },
  {
    path: '/config',
    name: 'Config',
    component: Config,
    meta: {
      layout: 'admin',
      pageName: 'Настройки'
    }
  },
  {
    path: '/security',
    name: 'Security',
    component: Security,
    meta: {
      layout: 'admin',
      pageName: 'Сотрудники СБ'
    }
  },
  {
    path: '/operators',
    name: 'Operators',
    component: operators,
    meta: {
      layout: 'admin',
      pageName: 'Операторы'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      layout: 'admin',
      pageName: 'Пользователи'
    }
  },
  {
    path: '/tender',
    name: 'Tender',
    component: tender,
    meta: {
      layout: 'admin',
      pageName: 'Торги'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: statistics,
    meta: {
      layout: 'admin',
      pageName: 'Статистика'
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '*') next({name: 'Error'})
  if (to.meta.layout === 'login' && store.getters.getToken) next({ name: 'Config' })
  else if (to.meta.layout === 'admin' && !store.getters.getToken) next({name: 'Login'})
  else if (!store.getters.getToken) next()
  else next()
})

export default router
