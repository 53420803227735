import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__default"},[_c('h3',[_vm._v("Общие данные")]),_c('div',{staticClass:"default__form__block"},[_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Правовая форма","error-messages":_vm.getErrors('legalform', _vm.$v.legalform)},model:{value:(_vm.legalform),callback:function ($$v) {_vm.legalform=$$v},expression:"legalform"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Название компании","error-messages":_vm.getErrors('inc',
     _vm.$v.inc)},model:{value:(_vm.inc),callback:function ($$v) {_vm.inc=$$v},expression:"inc"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"ОГРН компании","error-messages":_vm.getErrors('ogrn',
    _vm.$v.ogrn)},model:{value:(_vm.ogrn),callback:function ($$v) {_vm.ogrn=$$v},expression:"ogrn"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"ИНН компании","error-messages":_vm.getErrors('inn',
    _vm.$v.inn)},model:{value:(_vm.inn),callback:function ($$v) {_vm.inn=$$v},expression:"inn"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Адрес компании","error-messages":_vm.getErrors('address', _vm.$v.address)},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Email","error-messages":_vm.getErrors('email',
    _vm.$v.email)},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Email тех. поддержки","error-messages":_vm.getErrors('support', _vm.$v.support)},model:{value:(_vm.support),callback:function ($$v) {_vm.support=$$v},expression:"support"}}),_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"disabled":_vm.adminStatus,"label":"Телефон","error-messages":_vm.getErrors('tel', _vm.$v.tel)},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c(VTextField,{attrs:{"disabled":_vm.adminStatus,"label":"Минимальное значение от старта торгов до окончания (в минутах!)","error-messages":_vm.getErrors('tender_time', _vm.$v.tender_time)},model:{value:(_vm.tender_time),callback:function ($$v) {_vm.tender_time=$$v},expression:"tender_time"}}),(!_vm.adminStatus)?_c(VTextField,{attrs:{"label":"Ключ Dadata [ Обязательно! Указывайте слово Token и пробел перед ключом! ]","error-messages":_vm.getErrors('dadataKey', _vm.$v.dadataKey)},model:{value:(_vm.dadataKey),callback:function ($$v) {_vm.dadataKey=$$v},expression:"dadataKey"}}):_vm._e()],1),(!_vm.adminStatus)?_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"depressed":"","color":"success","disabled":_vm.loader},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }