<template>
  <div>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="sessions"
          :loading="loader"
          loading-text="Загрузка... Ожидайте!"
          style="cursor: pointer"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: ''
          }"
      >
        <template v-slot:item.date="{item}">
          <b>{{ getDate(item.date) }}</b>
        </template>
        <template v-slot:item.user_id="{item}">
          {{ item.user_id }}
        </template>
        <template v-slot:item.product_id="{item}">
          {{ getName(item) }}
        </template>
        <template v-slot:item.ip_address="{item}">
          {{ item.ip_address }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <div></div>
      <v-spacer></v-spacer>
      <v-btn
          color="blue darken-1"
          text
          @click="closeModal"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: 'product-session',
  props: {
    id: null
  },
  data() {
    return {
      headers: [
        {
          text: 'Время',
          align: 'start',
          filterable: true,
          value: 'date',
          class: "success--text subtitle-2 font-weight-bold"
        },
        {text: 'ID', value: 'user_id', class: "subtitle-2 font-weight-bold"},
        {text: 'Субъект', value: 'product_id', class: "subtitle-2 font-weight-bold"},
        {text: 'IP адрес', value: 'ip_address', class: "subtitle-2 font-weight-bold"},
      ],
      sessions: [],
      loader: true
    }
  },
  async created() {
    if (this.id) {
      const session = await this.$store.dispatch('getUserSession', {product_id: +this.id})
      if (session.length) this.sessions = session.reverse()
      this.loader = false
    }
  },
  destroyed() {
    this.sessions = []
  },
  methods: {
    getDate(date) {
      return moment.unix(date).format('LLL')
    },
    getName(item) {
      if (item.corporations) {
        if (+item.user.operator) return `Оператор: ${item.corporations.user}`
        else return item.corporations.inc
      } else if (item.employers) return `ИП ${item.employers.surname} ${item.employers.name} ${item.employers.patronymic}`
      else return 'Не зарегистрирован'
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>