<template>
  <h1>404 - такой страницы не существует!</h1>
</template>

<script>
export default {
  name: "error"
}
</script>

<style scoped>

</style>