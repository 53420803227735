<template>
  <div>
    <div v-if="!adminStatus" class="mb-4 mt-1">
      <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
          @click="addOperator"
      >

        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      Добавить оператора
    </div>
    <div class="card__default">
      <searchFilter v-if="operators.length" :headers="headers" :payload="operators" @filter="filterResult"/>
      <v-data-table
          :headers="headers"
          :items="operatorsFilter"
          @click:row="handleClick"
          style="cursor: pointer"
      >
        <template v-slot:item.department="{item}">
          {{ item.department }}
        </template>
        <template v-slot:item.products_length="{item}">
          <div class="table_link_btn" @click.stop="pathTradePage(item)">{{ item.products_length }}</div>
        </template>
        <template v-slot:item.moderation="{item}">
          <div v-html="getModeration(item.moderation)"></div>
        </template>
      </v-data-table>
    </div>

    <operator @data="operatorsData"/>

  </div>

</template>

<script>
import operator from "@/components/dialogs/operator/index.vue";
import searchFilter from "@/components/searchFilter";

export default {
  name: "operators",
  components: {operator, searchFilter},
  data() {
    return {
      headers: [
        {
          text: 'Оператор',
          align: 'start',
          filterable: true,
          value: 'corporations.user',
          class: "success--text subtitle-2 font-weight-bold",
          type: 'text'
        },
        {text: 'Отдел', value: 'department', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Телефон', value: 'corporations.phone', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Email', value: 'email', class: "subtitle-2 font-weight-bold", type: 'text'},
        { text: 'Создано торгов', value: 'products_length', class: 'subtitle-2 font-weight-bold' },
        { text: 'Выборано победителей', value: 'winners_length', class: 'subtitle-2 font-weight-bold' },
        { text: 'Заключено договоров', value: 'winners_contracts', class: 'subtitle-2 font-weight-bold' },
        {text: 'Статус', value: 'moderation', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            { title: 'Заблокирован', value: '0' },
            { title: 'Активен', value: '1' }
          ]}
      ],
      operators: [],
      operatorsFilter: [],
      departments: []
    }
  },
  computed: {
    getDepartments() {
      let deps = this.$store.getters.getDepartaments
      this.departments = deps
      return deps
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getDepartments(val) {
      this.departments = val
    }
  },
  mounted() {
    this.addDataInPayload()
  },
  methods: {
    async addDataInPayload() {
      let operators = await this.$store.dispatch('getOperators')
      for (let item of operators) {
        item.department = await this.getDepartanentItem(item.department_id)
        item.winners_length = item.winners.length || 0
        item.products_length = item.products.length || 0
        item.winners_contracts = item.winners.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue.contract),
            0
        );
      }
      this.operators = this.operatorsFilter = operators
    },
    operatorsData() {
      this.addDataInPayload()
    },
    getModeration(moderation) {
      if (+moderation) return '<div style="color:green">Активен</div>'
      else return '<div style="color:red">Заблокирован</div>'
    },
    handleClick(data) {
      this.$store.dispatch('modalActionOperators', data)
    },
    addOperator() {
      this.$store.dispatch('modalActionOperators', {corporations: null})
    },
    getDepartanentItem(id) {
      if (this.departments.length) {
        let depFind = []
        for (let itm of this.departments) {
          if(+itm.id===+id) depFind.push(itm)
        }
        if (depFind.length) return depFind[0].title
        else return 'Не выбран'
      }
      else return '-'
    },
    filterResult(data) {
      this.operatorsFilter = data
    },
    pathTradePage(item) {
      const queryParams = {
        operator: item.corporations.user
      };
      this.$router.push({ path: '/tender', query: queryParams });
    }
  }
}
</script>

<style scoped>

</style>
