import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCardText,[_c(VDataTable,{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.sessions,"loading":_vm.loader,"loading-text":"Загрузка... Ожидайте!","footer-props":{
          disableItemsPerPage: true,
          itemsPerPageText: ''
        }},scopedSlots:_vm._u([{key:"item.date",fn:function({item}){return [_c('b',[_vm._v(_vm._s(_vm.getDate(item.date)))])]}},{key:"item.user_id",fn:function({item}){return [_vm._v(" "+_vm._s(item.user_id)+" ")]}},{key:"item.product_id",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.getName(item))+" ")]}},{key:"item.ip_address",fn:function({item}){return [_vm._v(" "+_vm._s(item.ip_address)+" ")]}}])})],1),_c(VDivider),_c(VCardActions,[_c('div'),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Закрыть ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }