<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Удаление ед. измерения</span>
      </v-card-title>
      <v-card-text>
        Вы уверенны что хотите удалить: <b>{{ unitName }}</b> ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog=false"
        >
          Нет
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="delUnit"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "unit",
  data: () => ({
    dialog: false,
    id: null,
    unitName: ''
  }),
  beforeDestroy() {
    this.$store.dispatch('modalActionUnit', null)
  },
  computed: {
    getStoreUnit() {
      return this.$store.getters.getUnitsModal
    }
  },
  watch: {
    getStoreUnit(val) {
      if(val) {
        this.unitName = val.title
        this.id = val.id
        this.dialog = true
      }
      else {
        this.unitName = ''
        this.id = null
        this.dialog = false
      }
    }
  },
  methods: {
    async delUnit() {
      if (!this.id) return
      let res = await this.$store.dispatch('deleteUnit', {id: this.id})
      if (res && res.status === 200) {
        await this.$store.dispatch('modalActionUnit', null)
      }
      else alert('Что пошло не так!')
    }
  }
}
</script>

<style scoped>

</style>