<template>
    <div class="mb-5">
      <h5 class="mb-2">НОВЫЕ ТОРГИ</h5>
      <div v-if="loaders.newTrade">
        <v-progress-linear
            indeterminate
            color="green"
        ></v-progress-linear>
      </div>
      <div v-else>
        <div v-if="newTradeList.length">
          <div
              class="text-body-2 events__list"
              v-for="(item, idx) in newTradeList"
              :key="idx">
            <v-icon class="mr-3" size="30" color="#c69494"> mdi-circle-small </v-icon>
            {{ item.text }}
          </div>
        </div>
        <div v-else>Нет данных</div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'NewTrade',
  data() {
    return {
      loaders: {
        newTrade: true,
      },
      newTrade: [],
      newTradeList: [],
      getInterval: null
    }
  },
  created() {
    this.getNewTrade()
    this.getInterval = setInterval(()=> {
      this.newTradeList = []
      this.getNewTrade()
    }, 60000 )
  },
  destroyed() {
    clearInterval(this.getInterval)
    this.getInterval = null
  },
  watch: {
    newTrade(val) {
      if (val.length) this.newTradeBuilding()
    },
  },
  methods: {
    async getNewTrade() {
      try {
        const result = await axios('/get-new-trade', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (result.status === 200 && result.data.length) {
          this.newTrade = result.data
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loaders.newTrade = false
      }
    },
    newTradeBuilding() {
      if (this.newTrade.length) {
        for (const item of this.newTrade) {
          this.newTradeList.push(
              {
                text:
                    `${moment.unix(+item.date).format('LLL')} оператор №${item.user_id} - ${item.corporations.user} создал(а) торги №${item.id} ${item.product}`
              }
          )
        }
      }
    }
  }
}
</script>
