import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import Products from './modules/products'
import Users from './modules/users'
import Operators from "./modules/operators"
import Security from "@/store/modules/security";
import Departments from "./modules/departments"
import Owner from "./modules/owner"
import Units from "./modules/units"
import Initiators from "./modules/initiators";
import Statistics from "./modules/statistics"
import adminConfig from "@/store/modules/adminConfig";

Vue.use(Vuex)

export default new Vuex.Store({
    namespace: true,
    state: ()=>({
       Token: null
    }),
    mutations: {
       TokenM(state, token) {
           state.Token = token
       }
    },
    actions: {
        login({}, payload) {
            const formData = new FormData();
            formData.append('login', payload.login || null)
            formData.append('password', payload.password || null)
            try {
                return axios('/adm', {
                    method: 'post',
                    data: formData,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                })
            } catch (e) {
                console.error('Login error ', e)
            }
        }
    },
    getters: {
      getToken: state => state.Token
    },
    modules: {
        Products,
        Users,
        Operators,
        Security,
        Departments,
        Owner,
        Units,
        Initiators,
        Statistics,
        adminConfig
    }
})
