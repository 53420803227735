import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        departments: []
    }),
    mutations: {
        modalMutationDep(state, data) {
            state.modal = data || null
        },
        depatmentsMutations(state, data) {
            state.departments = data || []
        }
    },
    actions: {
        modalActionDep({commit}, data) {
            commit('modalMutationDep', data)
        },
        async getDepartments({commit}) {
            try {
                let res = await axios('/admin-all-departments', {
                    method: 'POST',
                    headers: {
                        "Authorization": store.getters.getToken
                    }
                })
                if (res && res.status === 200) {
                    let departments = res.data
                    if (departments.length) departments.map(item => item.show = true)
                    commit('depatmentsMutations', departments)
                }
                else console.error('Не удалось получить данные от /admin-all-departments')
            }
            catch (e) {
                console.error('ERROR IN getDepartments ', e)
            }
        },
        setDepartment({}, payload) {
          return axios('/admin-set-department', {
              method: 'POST',
              headers: {
                  "Authorization": store.getters.getToken
              },
              data: payload
          })
        },
        updateDepartment({}, payload) {
            return axios('/admin-update-department', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteDepartment({}, payload) {
            return axios('/admin-delete-department', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        }
    },
    getters: {
        getDepartmentsModal: state => state.modal,
        getDepartaments: state => state.departments
    }
}