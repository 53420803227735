import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import axiosPlugin from './plugins/axios'
import  './assets/style.css'
import VueCookies from 'vue-cookies'
import VueMask from 'v-mask'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(axiosPlugin.VueAxios, axiosPlugin.axios)
Vue.use(VueCookies)

Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
