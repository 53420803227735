import axios from 'axios'
import store from "@/store";
import VueCookies from 'vue-cookies'

export default {
    namespace: true,
    state: () => ({
        email: '',
    }),
    mutations: {
        setEmailMutation(state, data) {
            state.email = data
        }
    },
    actions: {
        async getAdminEmail({commit}, payload) {
            let res = await axios('/get-admin-email', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) {
                commit('setEmailMutation', res.data)
                return res.data
            }
            else {
                console.error('Что то пошло не так в adminConfig!')
                return 'Error!'
            }
        },
        async updateEmail({commit}, payload) {
            let res = await axios('/set-admin-email', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) {
                commit('setEmailMutation', res.data)
                return res.data
            }
            else {
                console.error('Что то пошло не так в adminConfig!')
                return 'Error!'
            }
        }
    },
    getters: {
        getAdminEmail: state => state.email
    }
}
