<template>
  <v-card
      :loading="loading"
      class="mx-auto my-12 pa-2"
      max-width="374"
  >
    <v-card-text>
      <h6 class="login__title">Административная панель торговой площадки Yaratelle</h6>
      <div class="login_subtitle">Укажите ваш логин и пароль и нажмите "Войти"</div>
      <div class="mb-6">
        <v-text-field
            type="text"
            label="Логин"
            hide-details="auto"
            v-model="login"
            :error-messages="getErrors('login', $v.login)"
        ></v-text-field>
        <v-text-field
            :append-icon="eyeON ? 'mdi-eye-outline' :'mdi-eye-off'"
            :type="eyeON ? 'text' : 'password'"
            label="Пароль"
            hide-details="auto"
            v-model="password"
            :error-messages="getErrors('password', $v.password)"
            @click:append="eyeON = !eyeON"
        ></v-text-field>
        <div class="data__error" v-if="dataErrorText">{{ dataErrorText }}</div>
      </div>
      <v-btn
          small
          color="primary"
          dark
          @click="goLogin"
          :disabled="loading"
      >
        Войти
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {required, minLength} from "vuelidate/lib/validators"

export default {
  name: "LoginForm",
  validations: {
    login: {required},
    password: {required, minLength: minLength(4)}
  },
  data() {
    return {
      loading: false,
      eyeON: false,
      login: '',
      password: '',
      dataErrorText: ''
    }
  },
  methods: {
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors

      switch (name) {
        case "login":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "password":
          !model.required ? errors.push("Поле не может быт пустым!") : !model.minLength ? errors.push("Значение «Password» должно содержать минимум 4 символа!") : ""
          break;
        default:
          break;
      }
      return errors;
    },
    async goLogin() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.loading = true
      let result = await this.$store.dispatch('login', {login: this.login, password: this.password})
      if (result.status === 200) {
        if (!result.data.success) {
          this.dataErrorText = 'Не верные логин или пароль!'
          setTimeout(()=>{
            this.dataErrorText = ''
          }, 3000)
        }
        else {
          await this.$cookies.set('admin', JSON.stringify(result.data))
          await this.$store.commit('TokenM', result.data.access_token)
          await this.$router.push('/events')
          window.location.reload()
        }
      }
      else {
        alert('[ERROR] Сервер вернул ошибку. Обратитесь в службу технической поддержки.')
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.login__title {
  font-size: 23px;
  font-weight: bold;
  color: #227ffe;
  margin-bottom: 5px;
}

.login_subtitle {
  font-size: 12px;
  font-weight: 200;
}

.data__error {
  color: red;
  margin-top: 10px;
}
</style>
