import store from "@/store";
import axios from "axios";

export default {
    namespace: true,
    state: () => ({
        statistics: [],
        statistics_market: [],
        statistics_offer: [],
        market: null,
        offer: null
    }),
    mutations: {
        statisticsMutation(state, data) {
            state.market = data || null
        },
        marketMutation(state, data) {
            state.market = data || null
        },
        statisticsMarketMutation(state, data) {
            state.statistics_market = data || []
        },
        offerMutation(state, data) {
            state.offer = data || null
        },
        statisticsOfferMutation(state, data) {
            state.statistics_offer = data || []
        },
    },
    actions: {
        setMarket({commit}, data) {
            commit('marketMutation', data)
        },
        setStatisticsMarket({commit}, data) {
            commit('statisticsMarketMutation', data)
        },
        setOffer({commit}, data) {
            commit('offerMutation', data)
        },
        setStatisticsOffer({commit}, data) {
            commit('statisticsOfferMutation', data)
        },
        async getStatics({commit}) {
            try {
                const result = await axios('/get-statistics', {
                    method: 'POST',
                    headers: {
                        "Authorization": store.getters.getToken
                    }
                })
                if (result.status === 200) {
                    commit('statisticsMutation', result.data)
                    return result.data
                }
            } catch (e) {
                console.log('Error getStatics action', e)
            }
        }
    },
    getters: {
        getStatistics: state => state.statistics,
        getMarketStatistic: state => state.market,
        getStatisticsMarketArray: state => state.statistics_market,
        getOfferStatistic: state => state.offer,
        getStatisticsOfferArray: state => state.statistics_offer,
    }
}
