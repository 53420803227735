<template>
  <div class="mb-5">
    <h5 class="mb-2">ВНЕСЕНИЕ ИЗМЕНЕНИЙ В ТОРГИ</h5>
    <div v-if="loader">
      <v-progress-linear
          indeterminate
          color="green"
      ></v-progress-linear>
    </div>
    <div v-else>
      <div v-if="List.length">
        <div
            class="text-body-2 events__list"
            v-for="(item, idx) in List"
            :key="idx">
          <v-icon class="mr-3" size="30" color="#c69494"> mdi-circle-small</v-icon>
          {{ item.text }}
        </div>
      </div>
      <div v-else>Нет данных</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'UpdateTrade',
  data() {
    return {
      loader: true,
      Payload: [],
      MultiPayload: [],
      List: [],
      getInterval: null
    }
  },
  created() {
    this.getPayload()
    this.getInterval = setInterval(()=> {
      this.List = []
      this.getPayload()
    }, 50000 )
  },
  destroyed() {
    clearInterval(this.getInterval)
    this.getInterval = null
  },
  watch: {
    Payload(val) {
      if (val && val.length) this.Building()
    },
    MultiPayload(val) {
      if (val && val.length) this.MultiBuilding()
    },
  },
  methods: {
    async getPayload() {
      try {
        const result = await axios('/get-update-trade', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (result.status === 200 && result.data.length) {
          this.Payload = result.data
        }
      } catch (e) {
        console.error(e)
      }

      try {
        const multi_result = await axios('/get-multi-update-trade', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (multi_result.status === 200 && multi_result.data.length) {
          this.MultiPayload = multi_result.data
        }
      } catch (e) {
        console.error(e)
      }
      this.loader = false
    },
    dataTransform(attr, val) {
      const attrDatetime = ['stop_date', 'start_date', 'timestamp', 'date']
      if (attrDatetime.includes(attr)) return moment.unix(+val).format('LLL')
      else if (attr === 'type') {
            if (val === 'offer') return 'Аукцион'
            if (val === 'market') return 'Тендер'
            else return 'Запрос предложений'
      }
      else if (attr === 'moderation') return val === '0' ? 'На модерации' : 'Снят с модерации'
      else if (attr === 'mailing_dep') return val === '0' ? 'Выкл.' : 'Вкл.'
      else if (attr === 'mailing_api') return val === '0' ? 'Выкл.' : 'Вкл.'
      else return val
    },
    Building() {
      if (this.Payload.length) {
        const attributeProductName = (attribute) => {
          switch (attribute) {
            case 'id':
              return '№ торгов'
              break;
            case 'user_id':
              return '№ оператора'
              break;
            case 'type':
              return 'Тип торгов'
              break;
            case 'product':
              return 'Название торгов'
              break;
            case 'start_date':
              return 'Начало торгов'
              break;
            case 'stop_date':
              return 'Окончание торгов'
              break;
            case 'volume':
              return 'Необходимый объем'
              break;
            case 'delivery_place':
              return 'Адрес поставки'
              break;
            case 'payment_term':
              return 'Условия оплаты'
              break;
            case 'payment_percent':
              return 'Первоночальный платеж в процентах'
              break;
            case 'balance_percent':
              return 'Оплатить в течение (дней)'
              break;
            case 'start_price':
              return 'Стартовая цена'
              break;
            case 'text':
              return 'Комментарии'
              break;
            case 'user_partner':
              return 'Приглашенные (рассылка)'
              break;
            case 'winner_id':
              return 'Победитель'
              break;
            case 'date':
              return 'Дата создания торгов'
              break;
            case 'status':
              return 'Статус'
              break;
            case 'moderation':
              return 'Модерация'
              break;
            case 'mailing_dep':
              return 'Рассылка подписанным поставщикам'
            case 'mailing_api':
              return 'Рассылка по внешним площадкам'
            case 'terms_reference':
              return 'Коммерческое предложение'
            case 'initiator':
              return 'Инициатор торгов'
              break;
            default:
              return 'Не удалось определить'
          }
        }
        for (const item of this.Payload) {
          this.List.push(
              {
                text:
                    `${moment.unix(+item.timestamp).format('LLL')} в торги №${item.product_id} администратором внесены изменения. Изменено: "${attributeProductName(item.attribute)}". Было: "${this.dataTransform(item.attribute, item.old_value)}". Стало: "${this.dataTransform(item.attribute, item.new_value)}".`
              }
          )
        }
      }
    },
    MultiBuilding() {
      if (this.MultiPayload.length) {
        const attributeProductName = (attribute) => {
          switch (attribute) {
            case 'id':
              return '№ продукта'
              break;
            case 'name':
              return 'Наименование продукта'
              break;
            case 'product':
              return 'Название торгов'
              break;
            case 'volume':
              return 'Объем продукта'
              break;
            case 'stop_date':
              return 'Окончание торгов'
              break;
            case 'volume':
              return 'Объем'
              break;
            case 'unit':
              return 'Единица измерения'
              break;
            case 'price':
              return 'Начальная цена продукта'
            case 'product_id':
              return 'Номер торгов'
              break;
            case 'id':
              return 'Номер продукта'
              break;
            default:
              return 'Не удалось определить'
          }
        }
        for (const item of this.MultiPayload) {
          if (item.old_value) {
            this.List.push(
                {
                  text:
                      `${moment.unix(+item.timestamp).format('LLL')} в торгах №${item.product_id} администратором внесены изменения в продукт №${item.multi_product_id}. Изменено: "${attributeProductName(item.attribute)}". Было: "${this.dataTransform(item.attribute, item.old_value)}". Стало: "${this.dataTransform(item.attribute, item.new_value)}".`
                }
            )
          }
          else {
            this.List.push(
                {
                  text:
                      `${moment.unix(+item.timestamp).format('LLL')} в торгах №${item.product_id} администратором создан новый продукт №${item.multi_product_id}. Добавлено поле: "${attributeProductName(item.attribute)}" с данными: "${this.dataTransform(item.attribute, item.new_value)}".`
                }
            )
          }

        }
      }
    }
  }
}
</script>
