<template>
  <div class="mb-5">
    <div v-if="loader">
      <v-progress-linear
          indeterminate
          color="green"
      ></v-progress-linear>
    </div>
    <div v-else>
      <div v-if="List.length">
        <div
            class="text-body-2 events__list"
            v-for="(item, idx) in List"
            :key="idx">
          <v-icon class="mr-3" size="30" color="#c69494"> mdi-circle-small </v-icon>
          {{ item.text }}
        </div>
      </div>
      <div v-else>Нет данных</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'UserDownloadDocs',
  data() {
    return {
      loader: true,
      Payload: [],
      List: [],
      getInterval: null,
    }
  },
  created() {
    this.getPayload()
    this.getInterval = setInterval(()=> {
      this.List = []
      this.getPayload()
    }, 30000 )
  },
  destroyed() {
    clearInterval(this.getInterval)
    this.getInterval = null
  },
  watch: {
    Payload(val) {
      if (val.length) this.Building()
    },
  },
  methods: {
    async getPayload() {
      try {
        const result = await axios('/user-download-docs', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (result.status === 200 && result.data.length) {
          this.Payload = result.data
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loader = false
      }
    },
    Building() {
      if (this.Payload.length) {
        for (const item of this.Payload) {
          if (item.type === 'corporations') {
            if (item.corporations.uchet && item.corporations.registr && item.corporations.director && item.corporations.ustav && item.corporations.reshenie && item.corporations.doverenost) {
              this.List.push(
                  {
                    text:
                        `Пользователь ${item.corporations.inc} загрузил полный пакет документов и ожидает снятия с модерации`
                  }
              )
            }
          }
          if (item.type === 'employers') {
            if (item.employers.passport && item.employers.uchet && item.employers.registr) {
              this.List.push(
                  {
                    text:
                        `Пользователь ИП ${item.employers.surname ? item.employers.surname : ''} ${item.employers.name ? item.employers.name : ''} ${item.employers.patronymic ? item.employers.patronymic : ''} загрузил полный пакет документов и ожидает снятия с модерации`
                  }
              )
            }
          }
        }
      }
    }
  }
}
</script>
