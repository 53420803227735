<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="sessions"
        :loading="loader"
        loading-text="Загрузка... Ожидайте!"
        style="cursor: pointer"
        :footer-props="{
          disableItemsPerPage: true,
          itemsPerPageText: ''
        }"
    >
      <template v-slot:item.date="{item}">
        <b>{{ getDate(item.date) }}</b>
      </template>
      <template v-slot:item.ip_address="{item}">
        {{ item.ip_address }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: 'security-session',
  props: {
    id: null
  },
  data() {
    return {
      headers: [
        {
          text: 'Время',
          align: 'start',
          filterable: true,
          value: 'date',
          class: "success--text subtitle-2 font-weight-bold"
        },
        {text: 'IP адрес', value: 'ip_address', class: "subtitle-2 font-weight-bold"},
      ],
      sessions: [],
      loader: true
    }
  },
  async created() {
    if (this.id) {
      const session = await this.$store.dispatch('getSecuritySession', {id: this.id})
      if (session.length) this.sessions = session.reverse()
      this.loader = false
    }
  },
  destroyed() {
    this.sessions = []
  },
  methods: {
    getDate(date) {
      return moment.unix(date).format('LLL')
    },
  }
}
</script>
