<template>
  <div class="wrapper">
    <Trade />
    <Attention />
  </div>
</template>

<script>
import Trade from "@/components/events/Trade/index";
import Attention from "@/components/events/Attention/index";
export default {
  name: 'events',
  components: {
    Trade,
    Attention
  }
}
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
</style>
