<template>
  <div class="card__default">
    <h3>Единицы измерения</h3>

    <div v-if="!adminStatus">
      <div class="mt-4" v-if="!addInput">
        <div>
          <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              @click="addInput=true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          Добавить ед. измерения
        </div>
        <v-divider class="mt-4" v-if="units.length"></v-divider>
      </div>
      <div class="card__default mt-4" v-else>
        <v-text-field label="Наименование ед. измерения" v-model="addUnit"></v-text-field>
        <div>
          <v-btn
              small
              color="secondary"
              dark
              @click="closeInput"
              class="mr-2"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="addUnit"
              small
              color="error"
              dark
              @click="addUni"
          >
            Добавить
          </v-btn>
        </div>
      </div>
    </div>

    <v-list-item
        v-for="(item, i) in units"
        :key="i"
    >
      <template v-if="item.show">
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="!adminStatus">
          <div>
            <v-icon class="icon__pencil mr-2" small @click="showUpdate(item.id)">mdi-pencil</v-icon>
          </div>
          <v-icon class="icon__delete" small @click="deleteUnit(item.id)">mdi-delete</v-icon>
        </v-list-item-icon>
      </template>
      <v-text-field
          v-else
          outlined
          class="mt-4"
          label="Наименование единицы измерения"
          v-model="item.title"
          append-icon="mdi-check"
          append-outer-icon="mdi-close"
          @focus="storeTitle={id: item.id, title: item.title}"
          @click:append="updateUnit(item.id, item.title)"
          @click:append-outer="closeUnit(item.id)"
      ></v-text-field>
    </v-list-item>
    <Dialog/>
  </div>
</template>

<script>
import Dialog from '@/components/dialogs/unit'
export default {
  name: "Units",
  components: {Dialog},
  data() {
    return {
      addInput: false,
      addUnit: '',
      units: [],
      storeTitle: null
    }
  },
  computed: {
    getStoreUnit() {
      return this.$store.getters.getUnitsModal
    },
    getUnitsStatus() {
      let Uni = this.$store.getters.getUnits
      this.units = Uni
      return Uni
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getStoreUnit() {
      this.getUnits()
    },
    getUnitsStatus(val) {
      this.units = val
    }
  },
  methods: {
    getUnits() {
      this.$store.dispatch('getUnits')
    },
    closeInput() {
      this.addInput = false
      this.addUnit = ''
    },
    async addUni() {
      let res = await this.$store.dispatch('setUnit', {title: this.addUnit})
      if (res && res.status === 200) {
        await this.$store.dispatch('getUnits')
        //this.units.push({id: res.data.id, title: this.addUnit, show: true})
      }
      else alert('Что пошло не так!')
      this.addInput = false
      this.addUnit = ''
    },
    showUpdate(id) {
      this.units.map(item => {
        if (item.id === id) item.show = false
        else item.show = true
        if (this.storeTitle && item.id === this.storeTitle.id) item.title = this.storeTitle.title
      })
      this.storeTitle = null
    },
    async updateUnit(id, title) {
      if (!title) {
        alert('Вы не можете сохранить пустое значение "Наименование ед. измерения"')
        return
      }
      let res = await this.$store.dispatch('updateUnit', {id, title})
      if (res && res.status === 200) await this.closeUnit(id, true)
    },
    getThisUnit(id) {
      return this.units.find(item => item.id === id)
    },
    async closeUnit(id, update=false) {
      let closeU = await this.getThisUnit(id) //this.units.find(item => item.id === id)
      if (closeU) {
        if (this.storeTitle && !update) closeU.title = this.storeTitle.title
        closeU.show = true
      }
      this.storeTitle = null
    },
    async deleteUnit(id) {
      let Unit = await this.getThisUnit(id)
      await this.$store.dispatch('modalActionUnit', {id, title: Unit.title})
    }
  }
}
</script>

<style scoped>

</style>
