<template>
  <div class="card__default">
    <h3>Отделы</h3>
    <div v-if="!adminStatus">
      <div class="mt-4" v-if="!addInput">
        <div>
          <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              @click="addInput=true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          Добавить отдел
        </div>
        <v-divider class="mt-4" v-if="departments.length"></v-divider>
      </div>
      <div class="card__default mt-4" v-else>
        <v-text-field label="Наименование отдела" v-model="addDepartment"></v-text-field>
        <div>
          <v-btn
              small
              color="secondary"
              dark
              @click="closeInput"
              class="mr-2"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="addDepartment"
              small
              color="error"
              dark
              @click="addDep"
          >
            Добавить
          </v-btn>
        </div>
      </div>
    </div>

    <v-list-item
        v-for="(item, i) in departments"
        :key="i"
    >
      <template v-if="item.show">
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="!adminStatus">
          <div>
            <v-icon class="icon__pencil mr-2" small @click="showUpdate(item.id)">mdi-pencil</v-icon>
          </div>
          <v-icon class="icon__delete" small @click="deleteDep(item.id)">mdi-delete</v-icon>
        </v-list-item-icon>
      </template>
      <v-text-field
          v-else
          outlined
          class="mt-4"
          label="Наименование отдела"
          v-model="item.title"
          append-icon="mdi-check"
          append-outer-icon="mdi-close"
          @focus="storeTitle={id: item.id, title: item.title}"
          @click:append="updateDep(item.id, item.title)"
          @click:append-outer="closeDep(item.id)"
      ></v-text-field>
    </v-list-item>
    <Dialog/>
  </div>
</template>

<script>
import Dialog from '@/components/dialogs/department'
export default {
  name: "Departments",
  components: {Dialog},
  data() {
    return {
      addInput: false,
      addDepartment: '',
      departments: [],
      storeTitle: null
    }
  },
  computed: {
    getStoreDep() {
      return this.$store.getters.getDepartmentsModal
    },
    getDepartamentsStatus() {
      let Dep = this.$store.getters.getDepartaments
      this.departments = Dep
      return Dep
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getStoreDep() {
      this.getDepartments()
    },
    getDepartamentsStatus(val) {
      this.departments = val
    }
  },
  methods: {
    getDepartments() {
      this.$store.dispatch('getDepartments')
    },
    closeInput() {
      this.addInput = false
      this.addDepartment = ''
    },
    async addDep() {
      let res = await this.$store.dispatch('setDepartment', {department: this.addDepartment})
      if (res && res.status === 200) {
        await this.$store.dispatch('getDepartments')
        //this.departments.push({id: res.data.id, title: this.addDepartment, show: true})
      }
      else alert('Что пошло не так!')
      this.addInput = false
      this.addDepartment = ''
    },
    showUpdate(id) {
      this.departments.map(item => {
        if (item.id === id) item.show = false
        else item.show = true
        if (this.storeTitle && item.id === this.storeTitle.id) item.title = this.storeTitle.title
      })
      this.storeTitle = null
    },
    async updateDep(id, title) {
      if (!title) {
        alert('Вы не можете сохранить пустое значение "Наименование отдела"')
        return
      }
      let res = await this.$store.dispatch('updateDepartment', {id, title})
      if (res && res.status === 200) await this.closeDep(id, true)
    },
    getThisDep(id) {
      return this.departments.find(item => item.id === id)
    },
    async closeDep(id, update=false) {
      let closeDep = await this.getThisDep(id) //this.departments.find(item => item.id === id)
      if (closeDep) {
        if (this.storeTitle && !update) closeDep.title = this.storeTitle.title
        closeDep.show = true
      }
      this.storeTitle = null
    },
    async deleteDep(id) {
      let Dep = await this.getThisDep(id)
      await this.$store.dispatch('modalActionDep', {id, title: Dep.title})
    }
  }
}
</script>

<style scoped>

</style>
