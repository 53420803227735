<template>
  <div class="card__default">
    <h3 class="events__title">Торги</h3>
    <NewTrade/>
    <EventsTrade/>
    <UpdateTrade/>
  </div>
</template>

<script>
import NewTrade from "@/components/events/Trade/NewTrade";
import UpdateTrade from "@/components/events/Trade/UpdateTrade";
import EventsTrade from "@/components/events/Trade/EventsTrade";

export default {
  name: 'TradeIndex',
  components: {
    NewTrade,
    UpdateTrade,
    EventsTrade
  }
}
</script>

<style>
.events__list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
  cursor: default;
  transition: .5s;
}

.events__list:hover {
  background-color: rgb(224 224 224 / 21%);
}
</style>
