import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        operators: []
    }),
    mutations: {
        modalMutation(state, data) {
            state.modal = data || null
        },
        operatorsMutations(state, data) {
            state.operators = data || []
        }
    },
    actions: {
        modalActionOperators({commit}, data) {
            commit('modalMutation', data)
        },
        async getOperators({commit}) {
            let res = await axios('/admin-all-operators', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                }
            })
            if (res && res.status === 200) {
                commit('operatorsMutations', res.data)
                return res.data
            }
            else console.error('ERROR in getOperators')
        },
        setOperator({}, payload) {
            return axios('/admin-set-operator', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        updateOperators({}, payload) {
            return axios('/admin-update-operators', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteOperator({}, payload) {
            return axios('/admin-delete-operators', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        async getOperatorSession({}, payload) {
            const res =  await axios('/get-operator-session',  {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) return res.data
            else return []
        }
    },
    getters: {
        getOperatorsModal: state => state.modal,
        getOperatorsData: state => state.operators
    }
}
