<template>
  <div>
    <div class="card__default">
      <h3>Изменение пароля</h3>
      <div class="default__form__block">
        <v-text-field label="Текущий пароль" v-model="oldPassword" />
        <v-text-field v-if="!!(oldPassword.length > 5)" label="Новый пароль" v-model="password" />
        <v-row v-if="!!(password.length > 5)">
          <v-col>
            <v-btn
                depressed
                color="primary"
                @click="passwordUpdate"
            >
              Сохранить
              <v-icon dark>
                mdi-arrow-right-thin
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="error" style="color: red">Ошибка! Возможно вы указали не верный текущий пароль.</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UpdatePassword',
    data() {
      return {
        oldPassword: '',
        password: '',
        saveBtn: false,
        error: false,
      }
    },
    watch: {
      oldPassword: function (val) {
        this.saveBtn = !!(val && val.length > 5)
        if (val) this.error = false
      }
    },
    methods: {
      async passwordUpdate() {
        if (this.oldPassword && this.password) {
          const result = await this.$store.dispatch('updatePassword', { password: this.oldPassword, newpassword:
            this.password
          });
          if(result !== 'Password is updated') {
            this.password = this.oldPassword = ''
            this.error = true
          }
          else {
            alert('Пароль успешно изменен! \n Вам необходимо пройти идентификацию с новым паролем!')
            this.$store.commit('TokenM', null)
            this.$cookies.remove('admin')
            await this.$router.push('/')
          }
        }
      }
    }
  }
</script>
