<template>
  <v-dialog
      v-model="dialog"
      max-width="1200"
  >
    <v-card v-if="user" :loading="loader">
      <v-card-title>
        <span class="text-h5" v-if="user && user.corporations">Участник: <b>{{ user.corporations.inc }}</b></span>
        <span class="text-h5"
              v-else-if="user.employers">Участник: <b>ИП {{ user.employers.surname }} {{
            user.employers.name
          }} {{ user.employers.patronymic }} </b></span>
        <span class="text-h5" v-else>Нет данных...</span>
        <v-spacer></v-spacer>
        <small class="subtitle-2 font-weight-light">*Поля не могут быть пустыми</small>
      </v-card-title>

      <v-card-text style="padding-bottom: 0">
        <template>
          <v-btn-toggle
              v-model="menu"
              color="teal"
              group
              class="xs-buttons"
          >
            <v-btn value="data">
              Данные
            </v-btn>
            <v-btn value="status">
              Статус
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-card-text>

      <div v-if="menu === 'data'">
        <v-container>
          <v-card-text>

            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="pb-0"
              >
                <v-select
                    :disabled="adminStatus"
                    v-model="selectStatus"
                    :items="statusUser"
                    item-text="title"
                    item-value="id"
                    label="Статус"
                    outlined
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="pb-0"
              >
                <v-text-field
                    :disabled="adminStatus"
                    v-model="user.email"
                    label="*Email"
                    outlined
                    required
                    :error-messages="getErrors('email', $v.user.email)"
                ></v-text-field>
              </v-col>
            </v-row>

            <!--     TYPE USER CONFIGURATION   -->
            <div v-if="user.corporations">
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.legalform"
                      label="*Организационно-правовая форма"
                      outlined
                      required
                      :error-messages="getErrors('corp_legalform', $v.user.corporations.legalform)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.inc"
                      label="*Сокращенное наименование"
                      outlined
                      required
                      :error-messages="getErrors('corp_inc', $v.user.corporations.inc)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.user"
                      label="*Представитель"
                      outlined
                      required
                      :error-messages="getErrors('corp_user', $v.user.corporations.user)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.phone"
                      label="*Телефон"
                      outlined
                      required
                      v-mask="'+7 (###) ###-##-##'"
                      :error-messages="getErrors('corp_phone', $v.user.corporations.phone)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.inn"
                      label="*ИНН"
                      outlined
                      required
                      :error-messages="getErrors('corp_inn', $v.user.corporations.inn)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.corporations.ogrn"
                      label="*ОГРН"
                      outlined
                      required
                      :error-messages="getErrors('corp_ogrn', $v.user.corporations.ogrn)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="user.employers">
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.surname"
                      label="*Фамилия"
                      outlined
                      required
                      :error-messages="getErrors('employer_surname', $v.user.employers.surname)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.name"
                      label="*Имя"
                      outlined
                      required
                      :error-messages="getErrors('employer_name', $v.user.employers.name)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.patronymic"
                      label="*Отчество"
                      outlined
                      required
                      :error-messages="getErrors('employer_patronymic', $v.user.employers.patronymic)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.phone"
                      label="*Телефон"
                      outlined
                      required
                      v-mask="'+7 (###) ###-##-##'"
                      :error-messages="getErrors('employer_phone', $v.user.employers.phone)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.inn"
                      label="*ИНН"
                      outlined
                      required
                      :error-messages="getErrors('employer_inn', $v.user.employers.inn)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus"
                      v-model="user.employers.ogrnip"
                      label="*ОГРНИП"
                      outlined
                      required
                      :error-messages="getErrors('employer_ogrnip', $v.user.employers.ogrnip)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div class="image__min__box" v-if="images && images.length">
              <div
                  v-for="item in images"
                  :key="item.doc"
                  style="margin-right: 14px; margin-bottom: 15px; display: inline-block;"
                  v-if="item.url"
              >
                <div style="position: relative">
                  <div class="image__delete" v-if="!adminStatus">
                    <v-icon @click="alertDelImage(item)">mdi-delete</v-icon>
                  </div>


                  <iframe
                      :src="getDocUrl(item)"
                      width="200px"
                      height="150px"
                  >
                  </iframe>
                  <div>
                    <a :href="getDocUrl(item)" target="_blank">{{ item.rus }}</a>
                  </div>
                </div>
              </div>
              <div
                  v-for="item in images"
                  :key="item.doc"
                  style="margin-right: 14px; margin-bottom: 15px;"
                  v-if="!item.url"
              >
                <div>Не загружен: <b>{{ item.rus }}</b></div>
              </div>
            </div>
          </v-card-text>
        </v-container>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog=false"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="!adminStatus"
              color="blue darken-1"
              text
              @click="update"
              :disabled="loader"
          >
            Изменить
          </v-btn>
        </v-card-actions>
      </div>

      <template v-if="menu === 'status'">
        <status :id="user.id" :date="user.date" @close="closeModal"/>
      </template>

    </v-card>
  </v-dialog>
</template>

<script>
import {required, email, numeric} from "vuelidate/lib/validators"
import status from "@/components/dialogs/user/status";

export default {
  name: "user",
  components: {
    status
  },
  data: () => ({
    dialog: false,
    user: null,
    selectStatus: 0,
    statusUser: [
      {id: 0, title: 'Модерация'},
      {id: 1, title: 'Активно'},
      {id: 2, title: 'Заблокирован'},
    ],
    images: [],
    loader: false,
    menu: 'data'
  }),
  validations() {
    if (this.user.corporations) {
      return {
        user: {
          email: {required, email},
          corporations: {
            legalform: {required},
            inc: {required},
            user: {required},
            phone: {required},
            inn: {required, numeric},
            ogrn: {required, numeric}
          }
        }
      }
    } else if (this.user.employers) {
      return {
        user: {
          email: {required, email},
          employers: {
            surname: {required},
            name: {required},
            patronymic: {required},
            phone: {required},
            inn: {required, numeric},
            ogrnip: {required, numeric}
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('modalActionUser', null)
  },
  computed: {
    getUserModal() {
      return this.$store.getters.getUsersModal
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getUserModal(val) {
      if (val) {
        this.user = val
        this.selectStatus = +val.moderation
        this.getImage(val)
        this.dialog = true
      } else {
        this.dialog = false
        this.user = null
        this.images = []
      }
    },
    dialog(val) {
      if (!val) {
        this.selectStatus = 0
        this.$store.dispatch('modalActionUser', null)
        this.user = null
        this.$store.dispatch('getUsers')
      }
    },
  },
  methods: {
    getDocUrl(item) {
      if (!item) return
      return window.location.hostname === 'localhost' ? `http://localhost:8888/images/docs/${item.url}` :
          `https://server.tender-yaratelle.ru/images/docs/${item.url}`
    },
    getImage(val) {
      if (val.corporations) {
        let corpData = [
          {rus: 'О назначении директора', doc: 'director', url: val.corporations.director || null},
          {rus: 'Доверенность на представителя', doc: 'doverenost', url: val.corporations.doverenost || null},
          {rus: 'Свидетельство о регистрации', doc: 'registr', url: val.corporations.registr || null},
          {rus: 'Решение о создании', doc: 'reshenie', url: val.corporations.reshenie || null},
          {rus: 'О постановке на учет', doc: 'uchet', url: val.corporations.uchet || null},
          {rus: 'Устав', doc: 'ustav', url: val.corporations.ustav || null},
        ]
        this.images = corpData
      } else if (val.employers) {
        let employersData = [
          {rus: 'Паспорт', doc: 'passport', url: val.employers.passport || null},
          {rus: 'О постановке на учет', doc: 'uchet', url: val.employers.uchet || null},
          {rus: 'Свидетельство о регистрации', doc: 'registr', url: val.employers.registr || null},
        ]
        this.images = employersData
      } else this.images = []
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors

      switch (name) {
        case "email":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.email ? errors.push("Email не валидный!") : ""
          break;
        case "corp_legalform":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "corp_inc":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "corp_user":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "corp_phone":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "corp_inn":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") : ""
          break;
        case "corp_ogrn":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") : ""
          break;
        case "employer_surname":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "employer_name":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "employer_patronymic":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "employer_phone":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "employer_inn":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") : ""
          break;
        case "employer_ogrnip":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") : ""
          break;
        default:
          break;
      }
      return errors;
    },
    async update() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const ctx = this
      let payload = {
        id: this.user.id,
        moderation: this.selectStatus,
        email: this.user.email
      }

      this.loader = true

      let payloadType;
      if (this.user.corporations) {
        payloadType = {
          user_id: this.user.id,
          type: 'corporations',
          legalform: this.user.corporations.legalform,
          inc: this.user.corporations.inc,
          user: this.user.corporations.user,
          phone: this.user.corporations.phone,
          inn: this.user.corporations.inn,
          ogrn: this.user.corporations.ogrn
        }
      } else if (this.user.employers) {
        payloadType = {
          user_id: this.user.id,
          type: 'employers',
          surname: this.user.employers.surname,
          name: this.user.employers.name,
          patronymic: this.user.employers.patronymic,
          phone: this.user.employers.phone,
          inn: this.user.employers.inn,
          ogrnip: this.user.employers.ogrnip
        }
      }

      if (!payloadType) return
      await this.$store.dispatch('updateUsers', payload)
      await this.$store.dispatch('updateUserType', payloadType)
      await this.$store.dispatch('getUsers')
      this.$emit('data')
      this.dialog = false
      this.loader = false
    },
    async alertDelImage(item) {
      if (!item) return
      let confResult = confirm('Удалить документ: "' + item.rus + '"?')
      if (confResult) {
        let payload = {
          user_id: this.user.id,
          user_type: this.user.type,
          doc: item.doc,
          doc_url: item.url
        }
        let res = await this.$store.dispatch('deleteDoc', payload)
        if (res && res.status === 200) {
          if (this.user.type === 'corporations') this.user.corporations = res.data.user
          else this.user.employers = res.data.user
          this.getImage(this.user)
          let userData = {
            user_id: this.user.id,
            user_type: this.user.type,
            data: res.data.user
          }
          this.$emit('user', userData)
        }
      }
    },
    closeModal() {
      this.dialog = false
      this.menu = 'data'
    }
  }
}
</script>

<style scoped>
.image__delete {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.22);
}

.image__delete > button {
  color: red;
}
</style>
