<template>
  <div>
    <div v-if="adminStatus === 'Начальник СБ'" class="mb-4 mt-1">
      <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
          @click="addSecurity"
      >

        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      Добавить сотрудника службы безопасности
    </div>
    <div class="card__default">
      <searchFilter v-if="security.length" :headers="headers" :payload="security" @filter="filterResult"/>
      <v-data-table
          :headers="headers"
          :items="securityFilter"
          @click:row="handleClick"
          style="cursor: pointer"
      >
        <template v-slot:item.moderation="{item}">
          <div v-html="getModeration(item.moderation)"></div>
        </template>
      </v-data-table>
    </div>

    <securityModal />

  </div>

</template>

<script>
import securityModal from "@/components/dialogs/security";
import searchFilter from "@/components/searchFilter";

export default {
  name: "security",
  components: {securityModal, searchFilter},
  data() {
    return {
      headers: [
        {
          text: 'Сотрудник',
          align: 'start',
          filterable: true,
          value: 'name',
          class: "success--text subtitle-2 font-weight-bold",
          type: 'text'
        },
        {text: 'Статус', value: 'moderation', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            { title: 'Заблокирован', value: '0' },
            { title: 'Активен', value: '1' }
          ]}
      ],
      security: [],
      securityFilter: [],
    }
  },
  created() {
    if (this.adminStatus !== 'Начальник СБ') this.$router.push('/')
  },
  computed: {
    adminStatus() {
      return this.$store.getters.getAdminRole
    },
    getSecurity() {
      return this.$store.getters.getSecurityData
    },
  },
  watch: {
    getSecurity(val) {
      this.securityFilter = val
    }
  },
  mounted() {
    this.addDataInPayload()
  },
  methods: {
    async addDataInPayload() {
      this.security = await this.$store.dispatch('getSecurity')
    },
    getModeration(moderation) {
      if (+moderation) return '<div style="color:green">Активен</div>'
      else return '<div style="color:red">Заблокирован</div>'
    },
    handleClick(data) {
      this.$store.dispatch('modalActionSecurity', data)
    },
    addSecurity() {
      this.$store.dispatch('modalActionSecurity', { id: null })
    },
    filterResult(data) {
      this.securityFilter = data
    }
  }
}
</script>

<style scoped>

</style>
