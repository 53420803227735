import axios from 'axios'
import store from "@/store";
import VueCookies from 'vue-cookies'

function getAdminRole(role) {
    if (role) {
        if (role === 'admin') return 'Администратор'
        if (role === 'director') return 'Директор'
        if (role === 'security_boss') return 'Начальник СБ'
        if (role === 'security') return 'Сотрудник СБ'
    }
    else return 'Нет роли админстратора'
}

export default {
    namespace: true,
    state: () => ({
        owner: [],
        admin: {
            id: null,
            role: '',
            name: '',
        }

    }),
    mutations: {
        ownerMutation(state, data) {
            state.owner = data || []
        },
        adminMutation(state, data) {
            state.admin = data
        }
    },
    actions: {
        getRole({commit}) {
            let cooke = VueCookies.get('admin')
            if(cooke) {
                commit('adminMutation', {
                    id: cooke.admin_id,
                    role: getAdminRole(cooke.role),
                    name: cooke.name,
                })
            }
        },
        async getOwner({commit}) {
            let res = await axios('/admin-get-owner', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                }
            })
            if (res && res.status === 200) commit('ownerMutation', res.data)
            else console.error('Что то пошло не так в getOwner!')
        },
        async updateOwner({commit}, payload) {
            let res = await axios('/admin-update-owner', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res && res.status === 200) commit('ownerMutation', res.data)
            else console.error('Что то пошло не так в updateOwner!')
        },
        async updatePassword({commit}, payload) {
            let res = await axios('/admin-check-password', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) return res.data
            else {
                console.error('Что то пошло не так в updateOwner!')
                return 'Error!'
            }
        }
    },
    getters: {
        getOwner: state => state.owner,
        getAdminRole: state => state.admin.role,
        getAdminName: state => state.admin.name,
        getAdminID: state => state.admin.id,
    }
}
