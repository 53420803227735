<template>
  <div>
    <div class="card__default">
      <h3>Конфигурационные данные</h3>
      <div class="default__form__block">
        <v-text-field v-model="email" label="Email для уведомлений" :error-messages="getErrors('email', $v.email)"/>
        <v-row>
          <v-col>
            <v-btn
                depressed
                color="primary"
                @click="setEmail"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="error" style="color: red" >Ошибка!</div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, email} from "vuelidate/lib/validators"
export default {
  name: 'AdminConfig',
  data() {
    return {
      email: '',
      saveBtn: false,
      error: '',
    }
  },
  validations: {
    email: {required, email},
  },
  computed: {
    adminEmail() {
      return this.$store.getters.getAdminEmail
    }
  },
  watch: {
    adminEmail(val) {
      if (val) this.email = val
    }
  },
  mounted() {
    this.$store.dispatch('getAdminEmail', { id: this.$store.getters.getAdminID })
  },
  methods: {
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors

      switch (name) {
        case "email":
          !model.required ? errors.push("Поле не может быт пустым!") : !model.email ? errors.push("Впишите email правильно!") : ""
          break;
        default:
          break;
      }
      return errors;
    },
    setEmail() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$store.dispatch('updateEmail', {
        id: this.$store.getters.getAdminID,
        email: this.email
      })
    }
  }
}
</script>
