import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    Table,
    TableRow,
    TableCell,
    WidthType,
    LevelFormat,
    AlignmentType,
    BorderStyle,
    convertInchesToTwip,
    SectionType,
    PageOrientation
} from 'docx'
import moment from "moment-timezone";
import numberSeparator from "number-separator";

export default async function (payload = null, type) {
    if (!payload) return

    // Собираем тип торгов
    let typeTender = {
        typeName: '',
        typeShortName: ''
    }
    if (type === 'market') {
        typeTender.typeName = 'Торги на понижение (Тендер)'
        typeTender.typeShortName = 'T'
    } else if (type === 'offer') {
        typeTender.typeName = 'Торги на повышение (Аукцион)'
        typeTender.typeShortName = 'А'
    } else {
        typeTender.typeName = 'Сбор коммерческих предложений'
        typeTender.typeShortName = 'КП'
    }

    // DOC PARAMS
    const TABLE_CELL_PARAMS = {
        borders: {
            top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        }
    };
    const TABLE_CELL_MARGINS = {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        left: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0.1),
    }

    // Собираем придожение к торгам (динамика торгов)
    const REPORT_DOC = []

    const REPORT_DOC_HEADER = new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        ...TABLE_CELL_PARAMS,
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `ОТЧЕТ ПО ЭТП "YARATELLE"`,
                                        bold: true,
                                        size: '9pt',
                                    }),
                                    new TextRun({
                                        text: `${moment().format('LLL')}`,
                                        bold: true,
                                        size: '9pt',
                                        break: 1
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            }),
        ],
    })
    const REPORT_DOC_TITLE = new Paragraph({
        children: [
            new TextRun({
                text: `${typeTender.typeName} - отчет №_____`,
                bold: true,
                size: '14pt',
            }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {
            before: 1180,
            after: 150
        },
    })

    // variable,
    // type,
    // product_id,
    // tender_name,
    // product_name,
    // start_date,
    // stop_date,
    // price,
    // volume,
    // unit,
    // winners,
    // bidding_start,
    // bidding_stop,
    // bidding_start_total,
    // bidding_stop_total,
    // benefit

    const REPORT_DOC_TABLE_HEAD = new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 1000,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: '№',
                                        size: '8pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 1000,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'ID торгов',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Наименование торгов',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Наименование товара',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Начало торгов',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Завершение торгов',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Начальная цена (руб.)',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Лучшая цена (руб.)',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Количество',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Ед. измерения',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                    new TableCell({
                        width: {
                            size: 4505,
                            type: WidthType.DXA,
                        },
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Участник с лучшей ценой',
                                        size: '9pt',
                                        bold: true
                                    })
                                ],
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        margins: TABLE_CELL_MARGINS
                    }),
                ]
            })
        ]
    })

    const REPORT_DOC_RESULT_TABLE_BODY = payload.map((item, idx) => new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: (idx+1).toString(),
                                            size: '8pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.product_id,
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.tender_name,
                                            size: '9pt',
                                            bold: true
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.product_name === item.tender_name && item.variable !== 'MULTI' ? '-' : item.product_name,
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: moment.unix(item.start_date).format('LL'),
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: moment.unix(item.stop_date).format('LL'),
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.bidding_start && !isNaN(+item.bidding_start)  ? numberSeparator(+item.bidding_start) : '0',
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.bidding_stop && !isNaN(+item.bidding_stop) ? numberSeparator(+item.bidding_stop) : '0',
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.volume ? numberSeparator(+item.volume) : '0',
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.unit ? item.unit : 'шт.',
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.CENTER,
                                })
                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                        new TableCell({
                            width: {
                                size: 4505,
                                type: WidthType.DXA,
                            },
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.best_user,
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.LEFT,
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: item.best_user_phone,
                                            size: '9pt',
                                        })
                                    ],
                                    alignment: AlignmentType.LEFT,
                                })

                            ],
                            margins: TABLE_CELL_MARGINS
                        }),
                    ]
                })
            ]
        })
    )

    if (REPORT_DOC_HEADER) REPORT_DOC.push(REPORT_DOC_HEADER)
    if (REPORT_DOC_TITLE) REPORT_DOC.push(REPORT_DOC_TITLE)
    if (REPORT_DOC_TABLE_HEAD) REPORT_DOC.push(REPORT_DOC_TABLE_HEAD)
    if (REPORT_DOC_RESULT_TABLE_BODY) REPORT_DOC.push(...REPORT_DOC_RESULT_TABLE_BODY)

    const doc = new Document({
        numbering: {
            config: [
                {
                    reference: 'my-numbering',
                    levels: [
                        {
                            level: 0,
                            format: LevelFormat.DECIMAL,
                            text: '%1.',
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: {left: 600, hanging: 260},
                                    sizeSize: 12,
                                },
                                run: {
                                    size: '9pt'
                                }
                            },
                        },
                    ],
                },
            ],
        },
        sections: [
            {
                properties: {
                    page: {
                        size: {
                            orientation: PageOrientation.LANDSCAPE,
                        },
                    },
                },
                children: [
                    ...REPORT_DOC,
                ],
            }
        ]
    });

    let base64 = Packer.toBase64String(doc)
    return base64
}
