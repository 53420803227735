<template>
  <div v-if="filterData.length" :style="`width: ${setWidth !== 'auto' ? setWidth + 'px' : setWidth}`">
    <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="400"
        :height="height"
    />
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs/legacy'
import moment from "moment";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    filterData: {
      type: Array,
      default: []
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    height: {
      type: Number,
      default: 300
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async filterData(val) {
      if (val) {
        const Test = await this.setLabels(val)
        this.chartData.labels = Test.Date
        this.chartData.datasets[0].data = Test.dataLength
      }
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Назначено торгов',
            backgroundColor: '#7ea6d9',
            data: []
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    setWidth() {
      return this.chartData.labels.length > 100 ? this.chartData.labels.length * 25 : 'auto'
    }
  },
  methods: {
    async setLabels(data) {
      const groupedByDate = {};
      for (const item of data) {
        const date = moment(item.start_date * 1000).format('MM.DD.YYYY');
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date].push(item);
      }

      const Date = [];
      const dataLength = []
      for (const date in groupedByDate) {
        const items = groupedByDate[date];
        Date.push(date);
        dataLength.push(items.length)
      }

      return { Date, dataLength };
    }
  }
}
</script>

