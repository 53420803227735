<template>
  <div class="mb-5">
    <h5 class="mb-2">СОВПАДЕНИЯ ИНН</h5>
    <div v-if="loader">
      <v-progress-linear
          indeterminate
          color="green"
      ></v-progress-linear>
    </div>
    <div v-else>
      <div v-if="List.length" class="overflow-x-auto" style="max-height: 400px; border: 1px solid #dfdfdf; border-radius: 10px">
        <div
            class="text-body-2 events__list"
            v-for="(item, idx) in List"
            :key="idx">
          <v-icon class="mr-3" size="30" color="#c69494"> mdi-circle-small</v-icon>
          {{ item.text }}
        </div>
      </div>
      <div v-else>Нет данных</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'MathUsersINN',
  data() {
    return {
      loader: true,
      EmployersPayload: [],
      CorporationsPayload: [],
      List: [],
    }
  },
  created() {
    this.getPayload()
  },
  watch: {
    EmployersPayload(val) {
      if (val.length) this.EmployersBuilding()
    },
    CorporationsPayload(val) {
      if (val.length) this.CorporationsBuilding()
    },
  },
  methods: {
    async getPayload() {
      try {
        const result = await axios('/math-employers-inn', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (result.status === 200 && result.data.length) {
          this.EmployersPayload = result.data
        }
      } catch (e) {
        console.error(e)
      }

      try {
        const result = await axios('/math-corporations-inn', {
          method: 'POST',
          headers: {
            "Authorization": this.$store.getters.getToken
          }
        })
        if (result.status === 200 && result.data.length) {
          this.CorporationsPayload = result.data
        }
      } catch (e) {
        console.error(e)
      }

      this.loader = false
    },
    async EmployersBuilding() {
      if (this.EmployersPayload.length) {
        const myPromise = new Promise((resolve, reject) => {
          const employersGroupINN = this.EmployersPayload.reduce((result, user) => {
            let groupKey = user.inn;
            if (!result[groupKey]) {
              result[groupKey] = [];
            }
            result[groupKey].push(user);
            return result;
          }, {})
          resolve(employersGroupINN)
        })

        myPromise
            .then(group => {
              for (const item in group) {
                let users = ''
                if ( item !== '165502100094') {
                  for (const user of group[item]) {
                    users += `ИП ${user.surname ? user.surname : ''} ${user.name ? user.name : ''} ${user.patronymic ?
                        user.patronymic : ''} № ${user.user_id} | `
                  }
                  this.List.push(
                      {
                        text:
                            `У пользователей: ${users} совпадает ИНН - ${item}`
                      }
                  )
                }
              }
            })
      }
    },
    CorporationsBuilding() {
      if (this.CorporationsPayload.length) {
        const myPromise = new Promise((resolve, reject) => {
          const employersGroupINN = this.CorporationsPayload.reduce((result, user) => {
            let groupKey = user.inn;
            if (!result[groupKey]) {
              result[groupKey] = [];
            }
            result[groupKey].push(user);
            return result;
          }, {})
          resolve(employersGroupINN)
        })

        myPromise
            .then(group => {
              for (const item in group) {
                let users = ''
                for (const user of group[item]) {
                  if (user.inc !== 'ПТИЦЕФАБРИКА "ЯРАТЕЛЬ" ФИЛИАЛ ОБЩЕСТВА С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ПТИЦЕВОДЧЕСКИЙ КОМПЛЕКС "АК БАРС"') {
                    users += `${user.inc ? user.inc : ''} № ${user.user_id} | `
                  }
                }
                this.List.push(
                    {
                      text:
                          `У пользователей: ${users} совпадает ИНН - ${item}`
                    }
                )

              }
            })
      }
    }
  }
}
</script>
