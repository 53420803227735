<template>
  <div class="card__default">
    <searchFilter v-if="products.length" :headers="headers" :payload="products" :query="queryParams"
                  @filter="filterResult"/>
    <v-data-table
        :headers="headers"
        :items="productsFilter"
        @click:row="handleClick"
        style="cursor: pointer"
    >
      <template v-slot:item.product="{item}">
        <div style="font-weight: 600; position: relative">
          <div>
            {{ item.product }}
            <div
                v-if="Number(item.date) + (3 * 24 * 60 * 60) > (Date.now() / 1000)"
                style="background-color: rgb(255,190,190); padding: 2px 10px; border-radius: 10px; font-size: 10px;
                display: inline-block; margin-bottom: 5px">
              Новые торги
            </div>
          </div>

        </div>
      </template>
      <template v-slot:item.type="{item}">
        {{ getType(item.type) }}
      </template>
      <template v-slot:item.start_date="{item}">
        {{ getDate(+item.start_date) }}
      </template>
      <template v-slot:item.stop_date="{item}">
        {{ getDate(+item.stop_date) }}
      </template>
      <template v-slot:item.status="{item}">
        <div :style="item.status === 'Торги идут'
        ? 'color: red'
        : item.status === 'В ожидании'
        ? 'color: green'
        : 'color: blue'">
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.moderation="{item}">
        <div v-if="+item.moderation" style="color: green">Активен</div>
        <div v-else style="color: red">Заблокирован</div>
      </template>
      <template v-slot:item.mailing_dep="{item}">
        <div>{{ +item.mailing_dep ? 'Вкл.' : 'Выкл.' }}</div>
      </template>
      <template v-slot:item.mailing_api="{item}">
        <div>{{ +item.mailing_api ? 'Вкл.' : 'Выкл.' }}</div>
      </template>
      <template v-slot:item.mailing="{item}">
        <div>Приглашенны: {{ item.user_partner ? item.user_partner : '-' }}</div>
      </template>
      <template v-slot:item.reference="{item}">
        <div style="text-align: center">
          <div
              style="position: relative"
              v-if="item.terms_reference"
              target="_blank"
              @click.stop="getReferenceImage(item.terms_reference)"
          >
            <div>
              <div v-if="item.refImageDel" style="padding: 5px 0">
                <span class="caption" style="line-height: 2px">Удалить техзадание?</span>
                <v-btn
                    x-small
                    depressed
                    color="error"
                    @click.stop="deleteReferenceImage(item.id, item.terms_reference)"
                >
                  УДАЛИТЬ
                </v-btn>
                <v-btn
                    x-small
                    depressed
                    color="primary"
                    @click.stop="deleteReferenceAlertClose(item)"
                >
                  НЕТ
                </v-btn>
              </div>
              <div v-else>
                <div
                    class="delete__alert_reference"
                    @click.stop="deleteReferenceAlert(item)"
                >
                  <v-icon color="red" small>mdi-close</v-icon>
                </div>
                <img src="@/assets/images/PDF.png" width="30" v-if="docType(item.terms_reference) === 'PDF'"/>
                <img src="@/assets/images/PNG.png" width="30" v-else-if="docType(item.terms_reference) === 'PNG'"/>
                <img src="@/assets/images/JPG.png" width="30" v-else-if="docType(item.terms_reference) === 'JPG'"/>
                <span v-else>Техзадание</span>
              </div>

            </div>
          </div>
          <div v-else>
            <div @click.stop="referenceFileClick(item.id)" class="upload__reference__btn">Нет</div>
            <input type="file" accept="image/*,application/pdf" hidden :id="`reference-file-${item.id}`"
                   @change="uploadReference($event, item)"/>
          </div>
        </div>
      </template>
      <template v-slot:item.initiator="{item}">
        {{ String(item.initiator) !== '0' ? item.initiator : 'Нет' }}
      </template>
      <template v-slot:item.addminutes_status="{item}">
        {{ item.addminutes_length }}
      </template>
      <template v-slot:item.extensions_status="{item}">
        {{ item.extensions_length }}
      </template>
    </v-data-table>

    <Dialog @subscribers="setSubscribers" @invited="setInvited" @data="updateProductsArray"/>

    <div v-if="alertSubscribers.status || alertInvited.status" class="error__alert">
      <v-alert
          v-model="alertSubscribers.status"
          border="left"
          close-text="Закрыть"
          :color="!alertSubscribers.message ? 'pink darken-1' : 'indigo'"
          dark
          dismissible
      >
        <div style="font-weight: bold; margin-bottom: 10px;">Подписчики:</div>
        <div v-if="!alertSubscribers.message" class="mb-2">
          <v-progress-circular
              indeterminate
              color="white"
          ></v-progress-circular>
          <span>Идет отправка сообщений подписчикам торгов...</span>
        </div>
        <v-divider></v-divider>
        <div v-if="alertSubscribers.errors.length" class="mt-2 mb-2">
          <div>Ошибки при отправке сообщений!</div>
          <div v-for="item of alertSubscribers.errors" class="custom__chips" v-html="item"></div>
        </div>
        <v-divider></v-divider>
        <div v-if="alertSubscribers.message" v-html="alertSubscribers.message" class="mt-1"></div>
      </v-alert>
      <v-alert
          v-model="alertInvited.status"
          border="left"
          close-text="Закрыть"
          :color="!alertInvited.message ? 'pink darken-1' : 'indigo'"
          dark
          dismissible
      >
        <div style="font-weight: bold; margin-bottom: 10px;">Приглашенные:</div>
        <div v-if="!alertInvited.message" class="mb-2">
          <v-progress-circular
              indeterminate
              color="white"
          ></v-progress-circular>
          <span>Идет отправка сообщений приглашенным на торги...</span>
        </div>
        <v-divider></v-divider>
        <div v-if="alertInvited.errors.length" class="mt-2 mb-2">
          <div>Ошибки при отправке сообщений!</div>
          <div v-for="item of alertInvited.errors" class="custom__chips" v-html="item"></div>
        </div>
        <v-divider></v-divider>
        <div v-if="alertInvited.message" v-html="alertInvited.message" class="mt-1"></div>
      </v-alert>
    </div>


  </div>
</template>

<script>
import Dialog from '@/components/dialogs/product/index.vue'
import searchFilter from "@/components/searchFilter";
import moment from "moment";

moment.locale('ru');
import tz from "moment-timezone"

moment.tz('Europe/Moscow');
export default {
  name: "tender",
  components: {Dialog, searchFilter},
  data() {
    return {
      alertSubscribers: {
        status: false,
        loader: false,
        message: '',
        errors: [],
      },
      alertInvited: {
        status: false,
        loader: false,
        message: '',
        errors: [],
      },
      refImageDel: false,
      headers: [
        {
          text: 'Название',
          align: 'start',
          filterable: true,
          value: 'product',
          class: "success--text subtitle-2 font-weight-bold",
          type: 'text',
        },
        {text: '№', value: 'id', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Оператор', value: 'corporations.user', class: "subtitle-2 font-weight-bold", type: 'text'},
        {
          text: 'Тип', value: 'type', class: "subtitle-2 font-weight-bold", type: 'select', items: [{
            title: 'Тендер',
            value: 'market'
          }, {title: 'Аукцион', value: 'offer'}]
        },
        {text: 'Начало', value: 'start_date', class: "subtitle-2 font-weight-bold", type: 'datepicker'},
        {text: 'Завершение', value: 'stop_date', class: "subtitle-2 font-weight-bold"},
        {
          text: 'Статус', value: 'status', class: "subtitle-2 font-weight-bold",
          type: 'select',
          items: [
            {title: 'Торги завершены', value: 'Торги завершены'},
            {title: 'В ожидании', value: 'В ожидании'},
            {title: 'Торги идут', value: 'Торги идут'}
          ]
        },
        {
          text: 'Модерация', value: 'moderation', class: "subtitle-2 font-weight-bold", type: 'select', items: [
            {title: 'Заблокирован', value: '0'},
            {title: 'Активен', value: '1'}
          ]
        },
        {text: 'Адрес', value: 'delivery_place', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Условия', value: 'term', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Комментарии', value: 'text', class: "subtitle-2 font-weight-bold", type: 'text'},
        {
          text: 'Рассылка подписчикам', value: 'mailing_dep', class: "subtitle-2 font-weight-bold",
          type: 'select',
          items: [
            {title: 'Вкл', value: '1'},
            {title: 'Выкл', value: '0'}
          ]
        },
        {
          text: 'API', value: 'mailing_api', class: "subtitle-2 font-weight-bold",
          type: 'select',
          items: [
            {title: 'Вкл', value: '1'},
            {title: 'Выкл', value: '0'}
          ]
        },
        {text: 'Приглашенны', value: 'partners', class: "subtitle-2 font-weight-bold", type: 'text'},
        {
          text: 'Техзадание', value: 'reference', class: "subtitle-2 font-weight-bold",
          type: 'select',
          items: [
            {title: 'Техзадание', value: '1'},
            {title: 'Нет', value: '0'}
          ]
        },
        {text: 'Инициатор', value: 'initiator', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Добавленное время', value: 'addminutes_status', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            {title: 'Да', value: '1'},
            {title: 'Нет', value: '0'}
          ]
        },
        {text: 'Переторг', value: 'extensions_status', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            {title: 'Да', value: '1'},
            {title: 'Нет', value: '0'}
          ]
        },
      ],
      products: [],
      productsFilter: [],
      queryParams: null,
    }
  },
  async created() {
    await this.getProducts()
  },
  mounted() {
    this.queryParams = this.$route.query || null;
  },
  computed: {
    Initiators() {
      return this.$store.getters.getInitiators
    }
  },
  watch: {
    Initiators(val) {
      if (val.length) {
        this.headers = this.headers.map(item => {
          if (item.value === 'initiator') {
            item.type = 'select'
            item.items = val.map(i=>({
              title: i.name,
              value: i.name
            }))
          }
          return item
        })
      }
    }
  },
  methods: {
    async getProducts() {
      let productsResult = await this.$store.dispatch('getProducts')
      if (productsResult.status === 200) {
        if (productsResult.data.length) {
          for (let item of productsResult.data) {
            item.status = this.getTenderStatus(item)
            item.term = this.getTerm(item)
            item.reference = this.getReference(item)
            item.partners = item.user_partner ? item.user_partner : '-'
            item.text = item.text ? item.text : '-'
            item.addminutes_status = item.addminutes.length ? '1' : '0'
            item.addminutes_length = item.addminutes.length ? item.addminutes.length : '0'
            item.extensions_status = item.extensions.length ? '1' : '0'
            item.extensions_length = item.extensions.length ? item.extensions.length : '0'
          }
        }
        this.products = this.productsFilter = productsResult.data
      } else alert('[ERROR] Что то пошло не так! Свяжитесь с технической поддержкой.')
    },
    updateProductsArray() {
      this.getProducts()
    },
    getTerm(data) {
      return data.payment_term !== 'Нет'
          ?
          `${data.payment_term} ${data.payment_percent}% ${
              data.payment_percent === 100 ? 'не позднее ' + data.balance_percent : 'остаток в течении ' +
                  data.balance_percent
          } дней`
          :
          'Без условий'
    },
    getReference(data) {
      return data.terms_reference ? '1' : '0'
    },
    getDate(date) {
      return moment.unix(date).format('LLL')
    },
    getTenderStatus(date) {
      let {stop_date, start_date} = date
      if (moment.unix(+stop_date).valueOf() < moment().valueOf()) {
        return 'Торги завершены'
      } else if (moment.unix(+start_date).valueOf() > moment().valueOf()) {
        return 'В ожидании'
      } else return 'Торги идут'
    },
    // ----
    handleClick(data) {
      this.$store.dispatch('modalAction', data)
    },
    docType(doc) {
      if (!doc) return
      let typeArr = doc.split('.')
      if (typeArr.length > 1) {
        let typeRev = typeArr.reverse()
        if (typeRev[0] === 'pdf') return 'PDF'
        else if (typeRev[0] === 'png') return 'PNG'
        else if (typeRev[0] === 'jpg' || typeRev[0] === 'jpeg') return 'JPG'
        else return null
      }
    },
    getImageURL(image) {
      if (!image) return
      return window.location.hostname === 'localhost' ? 'http://localhost:8888/images/tor/' + image :
          'https://server.tender-yaratelle.ru//images/tor/' + image
    },
    getType(type) {
      if (type === 'market') return 'Тендер'
      else if (type === 'offer') return 'Аукцион'
      else if (type === 'request-market') return 'Запрос предложений'
      else if (type === 'request-offer') return 'Запрос котировок'
    },
    setSubscribers(data) {
      if (data.message === 'start') {
        this.alertSubscribers.status = true
      }
      let promise = new Promise (resolve => {
        setTimeout(() => {
          if (data.error) this.alertSubscribers.errors.push(data.error)
          else {
            if (data.message !== 'start') this.alertSubscribers.message = data.message
          }
          resolve(true)
        }, 0)
      })
      promise.then(() => {
        setTimeout(()=>this.alertSubscribers.status = false, 8000)
      })

    },
    setInvited(data) {
      if (data.message === 'start') {
        this.alertInvited.status = true
      }
      let promise = new Promise (resolve => {
        setTimeout(() => {
          if (data.error) {
            this.alertInvited.errors.push(data.error)
          }
          else {
            if (data.message !== 'start') this.alertInvited.message = data.message
          }
          resolve(true)
        }, 0)
      })
      promise.then(() => {
        setTimeout(()=>this.alertInvited.status = false, 8000)
      })
    },
    getReferenceImage(reference) {
      window.open(this.getImageURL(reference), "_blank")
    },
    deleteReferenceAlert(item) {
      const itemIdx = this.productsFilter.findIndex(inner => inner.id === item.id)
      this.productsFilter[itemIdx].refImageDel = true
      this.productsFilter.push({})
      this.productsFilter.pop()
    },
    deleteReferenceAlertClose(item) {
      const itemIdx = this.productsFilter.findIndex(inner => inner.id === item.id)
      this.productsFilter[itemIdx].refImageDel = false
      this.productsFilter.push({})
      this.productsFilter.pop()
    },
    async deleteReferenceImage(id, reference) {
      const payload = {id, reference}
      const res = await this.$store.dispatch('deleteReferenceImage', payload)
      if (res && res.status === 200) {
        this.deleteReferenceAlertClose({id})
        const itemIdx = this.productsFilter.findIndex(inner => inner.id === id)
        this.productsFilter[itemIdx].terms_reference = ''
        this.productsFilter.push({})
        this.productsFilter.pop()
      }
    },
    referenceFileClick(id) {
      const reference = document.getElementById('reference-file-' + id)
      if (reference) reference.click()
      this.$store.dispatch('modalAction', false)
    },
    async uploadReference(e, item) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return
      const file = files[0]
      const fileSize = Math.ceil(file.size / 1024)
      const fileType = file.type
      if (this.errorFileType(fileType) || this.errorFileSize(fileSize)) {
        alert('Не верный тип файла (png, jpg, jpeg, pdf) или превышен максимальный размер файла 128mb')
        return;
      }

      let formData = new FormData();
      if (file) {
        formData.append('file', file)
        formData.append('id', item.id)
      }
      const res = await this.$store.dispatch('uploadReferenceImage', formData)

      if (res && res.status === 200) {
        if (res.data === 'No model upload' || res.data === 'No files') alert('ERROR ' + res.data)
        else {
          const itemIdx = this.products.findIndex(inner => inner.id === item.id)
          this.products[itemIdx].terms_reference = res.data
          this.products.push({})
          this.products.pop()
        }
      }
    },
    errorFileType(fileType) {
      if (!fileType) return false
      if (fileType === 'application/pdf') return false
      else if (fileType === 'image/png') return false
      else if (fileType === 'image/jpg') return false
      else if (fileType === 'image/jpeg') return false
      else return true
    },
    errorFileSize(fileSize) {
      if (!fileSize) return false
      if (fileSize >= 131072) return true
      else return false
    },
    filterResult(data) {
      this.productsFilter = data
    }
  }
}
</script>

<style scoped>
.error__alert {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
</style>
