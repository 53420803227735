<template>
  <v-app id="inspire">
    <div v-if="layout==='login'" class="login__container d-flex align-center justify-center">
      <LoginForm/>
    </div>
    <div v-else>
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          app
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              <v-fade-transition>
                <v-img v-if="!mini" src="./assets/images/logo.svg"/>
                <v-img v-else src="./assets/images/Y.png"/>
              </v-fade-transition>
            </v-list-item-title>
            <v-list-item-subtitle class="mt-2 font-weight-bold text-right text-uppercase">{{ getAdminRole }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list shaped>
          <v-list-item-group
              v-model="selectedBarItem"
              color="primary"
              class="mt-6"
          >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="item.link"
            >
              <v-list-item-icon>
                <v-icon
                    :color="item.iconColor"
                >{{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text--secondary subtitle-1" title="item.title">
                  {{ item.title }}
                  <span
                      v-if="item.title === 'Участники' && moderationAllDocsCount"
                      class="custom__badge"
                      @click.stop="goUsersFilter"
                  >{{ moderationAllDocsCount}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
          app
          absolute
          color="#fcb69f"
          dark
          prominent
          shrink-on-scroll
          src="/bg-yaratelle.jpeg"
          scroll-target="#scrolling-techniques-3"
          ref="bar"
          id="bar"
      >
        <template v-slot:img="{ props }">
          <v-img
              v-bind="props"
              gradient="to top right, rgb(45 206 137), rgb(45 206 204 / 45%)"
          ></v-img>
        </template>

        <v-btn
            icon
            @click.stop="drawer = !drawer"
        >
          <v-icon>{{ !drawer ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{ pageName }}</v-toolbar-title>
        <v-spacer></v-spacer>
<!--        <v-btn-->
<!--            fab-->
<!--            dark-->
<!--            x-small-->
<!--            color="cyan"-->
<!--            class="mt-2 mr-2"-->
<!--        >-->
<!--          <v-icon>mdi-email</v-icon>-->
<!--        </v-btn>-->
        <v-btn
            fab
            dark
            x-small
            color="pink"
            class="mt-2"
        >
          <v-icon @click="exit">mdi-exit-to-app</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <v-sheet
            id="scrolling-techniques-3"
            class="overflow-y-auto pa-4"
            style="background-color: rgb(247 247 247);"
        >
          <router-view></router-view>
        </v-sheet>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  components: {LoginForm},
  data() {
    return {
      selectedBarItem: 0,
      items: [
        {title: 'События', icon: 'mdi-alert-rhombus-outline', iconColor: 'deep-orange darken-2', link: '/events'},
        {title: this.getAdminRole === 'Администратор' ? 'Настройки' : 'Общие данные', icon: 'mdi-tune-variant',
          iconColor:
              'green darken-2', link:
    '/config'},
        {title: 'Операторы', icon: 'mdi-account-supervisor', iconColor: 'deep-orange darken-2', link: '/operators'},
        {title: 'Участники', icon: 'mdi-account-supervisor-circle', iconColor: 'blue darken-2', link: '/users'},
        {title: 'Торги', icon: 'mdi-gavel', iconColor: 'purple darken-2', link: '/tender'},
        {title: 'Статистика', icon: 'mdi-chart-areaspline', iconColor: 'warning darken-2', link: '/statistics'},
      ],
      menu: [],
      drawer: true,
      mini: false,
      moderationAlD: 0
    }
  },
  created() {
    if (this.layout !== 'login') {
      this.$store.dispatch('getRole')
      this.setSecuritySession()
      this.getOwner()
      this.getDepartments()
      this.getOperators()
      this.getSecurity()
      this.getUsers()
      this.getUnits()
      this.getInitiators()
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'login'
    },
    pageName() {
      return this.$route.meta.pageName || ''
    },
    moderationAllDocsCount() {
      return this.$store.getters.getModerationAllDocs.length ? this.$store.getters.getModerationAllDocs.length : ''
    },
    UserModalStatus() {
      return this.$store.getters.getUsersModal
    },
    getAdminRole() {
      return this.$store.getters.getAdminRole
    }
  },
  watch: {
    UserModalStatus(val) {
      if (!val) this.getUsers()
    },
    getAdminRole(val) {
      if (val === 'Начальник СБ') this.items = [...this.items, {title: 'Сотрудники СБ', icon: 'mdi-shield-account',
        iconColor: 'black', link: '/security'}]
    }
  },
  async mounted() {
    if (this.layout !== 'login') {
      let scrollingTechniques3 = await this.getContentHeight()
      this.scrollContentHeight(scrollingTechniques3)
    }
  },
  methods: {
    setSecuritySession() {
      if(this.getAdminRole === 'Сотрудник СБ') {
        this.$store.dispatch('setSession', {id: this.$store.getters.getAdminID})
      }
    },
    getOwner() {
      this.$store.dispatch('getOwner')
    },
    getDepartments() {
      this.$store.dispatch('getDepartments')
    },
    getOperators() {
      this.$store.dispatch('getOperators')
    },
    getSecurity()
    {
      this.$store.dispatch('getSecurity')
    },
    async getUsers() {
      const usersResult = await this.$store.dispatch('getUsers')
      let moderation = []
      if (usersResult.length) {
        for (let item of usersResult) {
          if (+item.moderation === 0) {
            if (item.employers && item.employers.passport && item.employers.uchet && item.employers.registr)
              moderation.push(item)
            if (item.corporations && item.corporations.uchet && item.corporations.registr &&
                item.corporations.director && item.corporations.ustav && item.corporations.reshenie &&
                item.corporations.doverenost) moderation.push(item)
          }
        }
        if (moderation.length) await this.$store.dispatch('moderationAllDocsAction', moderation)
      }
    },
    getUnits() {
      this.$store.dispatch('getUnits')
    },
    getInitiators() {
      this.$store.dispatch('getInitiators')
    },
    scrollContentHeight(elem) {
      if (this.layout === 'login') return
      elem.addEventListener('scroll', () => {
        this.getContentHeight()
      })
    },
    getContentHeight() {
      let windowHeight = window.innerHeight
      let barHeight = document.getElementById('bar')
      let scrollingTechniques3 = document.getElementById('scrolling-techniques-3')
      if (!windowHeight && !barHeight && !scrollingTechniques3) return
      let computedHeight = windowHeight - barHeight.clientHeight
      scrollingTechniques3.style.height = computedHeight + 'px'
      return scrollingTechniques3
    },
    exit() {
      this.$store.commit('TokenM', null)
      this.$cookies.remove('admin')
      this.$router.push('/')
    },
    goLink(link) {
      this.$router.push(link)
    },
    goUsersFilter() {
      // console.log('LINK')
      // const queryParams = {
      //   docs: 'Комплект',
      //   moderation: 'Модерация'
      // };
      // this.$router.push({ path: '/users', query: queryParams });
      this.items[3].link = '/users?docs=Комплект&moderation=0'
    },
  },

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

$body-font-family: 'Open Sans', sans-serif !important;
$heading-font-family: 'Open Sans', sans-serif !important;
</style>

<style scoped>
.login__container {
  width: 100%;
  height: 100vh;
  color: red;
}

.login__container {
  /*https://yaratelle.ru/wp-content/uploads/2019/11/2-4.jpg*/
  background-image: url("../public/bg-yaratelle.jpeg");
  background-size: cover;
  overflow: hidden;
}

.loginOverlay {
  background: rgba(33, 150, 243, 0.3);
}

.photoCredit {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>

