<template>
  <div>
    <v-row>
      <v-col>
        <div
            class="box"
            :style="toggle ? 'border: 2px solid #9595ff; cursor: pointer' : 'cursor: pointer'"
            @click="toggle = true"
        >
          <div class="title">Экономия</div>
          <div class="subtitle">Торги на понижение</div>
          <v-progress-circular
              v-if="loader"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <div v-else-if="benefit_market">
            <div class="price">{{ setSeparate(+benefit_market) }} <span>руб.</span></div>
            <v-btn
                depressed
                color="success"
                :disabled="!toggle || !statisticsMarketFilter.length"
                @click="docx('market')"
            >
              Скачать отчет
            </v-btn>
          </div>
          <div v-else>Нет данных</div>
        </div>
        <div class="vertical__line" v-if="toggle"></div>
      </v-col>
      <v-col>
        <div
            class="box"
            :style="!toggle ? 'border: 2px solid #9595ff; cursor: pointer' : 'cursor: pointer'"
            @click="toggle = false"
        >
          <div class="title">Доход</div>
          <div class="subtitle">Торги на повышение</div>
          <v-progress-circular
              v-if="loader"
              indeterminate
              color="primary"
          ></v-progress-circular>
          <div v-else-if="benefit_offer">
            <div class="price">{{ setSeparate(+benefit_offer) }} <span>руб.</span></div>
            <v-btn
                depressed
                color="success"
                :disabled="toggle || !statisticsOfferFilter.length"
                @click="docx('offer')"
            >
              Скачать отчет
            </v-btn>
          </div>
          <div v-else>Нет данных</div>
        </div>
        <div class="vertical__line" v-if="!toggle"></div>
      </v-col>
    </v-row>

    <div>
      <div class="card__default" style="overflow: auto; margin: 0">
        <BarChart v-if="toggle" :filterData="statisticsMarketFilter"/>
        <BarChart v-else :filterData="statisticsOfferFilter"/>
      </div>
      <div class="vertical__line"/>
    </div>

    <div v-show="toggle" class="card__default">
      <searchFilter
          v-if="statistics_market.length"
          :headers="headers"
          :payload="statistics_market"
          @filter="filterMarketResult"
      />
      <v-data-table
          :headers="headers"
          :items="statisticsMarketFilter"
          :loading="loader"
      >
        <template v-slot:item.product_name="{item}">
          {{ item.product_name === item.tender_name && item.variable !== 'MULTI' ? '-' : item.product_name }}
        </template>
        <template v-slot:item.start_date="{item}">
          {{ getDate(item.start_date) }}
        </template>
        <template v-slot:item.bidding_start="{item}">
          <div v-if="item.bidding_start === 'Без начальной цены'" style="color: #b05050">{{ item.bidding_start }}</div>
          <div v-else>{{ item.bidding_start }}</div>
        </template>
        <template v-slot:item.bidding_stop="{item}">
          <div v-if="item.bidding_stop === 'Нет'" style="color: #b05050">{{ item.bidding_stop }}</div>
          <div v-else>{{ item.bidding_stop }}</div>
        </template>
        <template v-slot:item.best_user="{item}">
          <div v-if="item.best_user === 'Нет'" style="color: #b05050">{{ item.best_user }}</div>
          <div v-else>{{ item.best_user }}</div>
        </template>
      </v-data-table>
    </div>

    <div v-show="!toggle" class="card__default">
      <searchFilter
          v-if="statistics_offer.length"
          :headers="headers"
          :payload="statistics_offer"
          @filter="filterOfferResult"
      />
      <v-data-table
          :headers="headers"
          :items="statisticsOfferFilter"
          :loading="loader"
      >
        <template v-slot:item.product_name="{item}">
          {{ item.product_name === item.tender_name && item.variable !== 'MULTI' ? '-' : item.product_name }}
        </template>
        <template v-slot:item.start_date="{item}">
          {{ getDate(item.start_date) }}
        </template>
        <template v-slot:item.bidding_start="{item}">
          <div v-if="item.bidding_start === 'Без начальной цены'" style="color: #b05050">{{ item.bidding_start }}</div>
          <div v-else>{{ item.bidding_start }}</div>
        </template>
        <template v-slot:item.bidding_stop="{item}">
          <div v-if="item.bidding_stop === 'Нет'" style="color: #b05050">{{ item.bidding_stop }}</div>
          <div v-else>{{ item.bidding_stop }}</div>
        </template>
        <template v-slot:item.best_user="{item}">
          <div v-if="item.best_user === 'Нет'" style="color: #b05050">{{ item.best_user }}</div>
          <div v-else>{{ item.best_user }}</div>
        </template>
      </v-data-table>
    </div>

  </div>
</template>

<script>
import numberSeparator from "number-separator";
import b64toBlob from "b64-to-blob";
import {saveAs} from "file-saver"
import report from "@/plugins/report";
import moment from "moment";
import searchFilter from "@/components/searchFilter";
import BarChart from "@/components/charts/Bar"

export default {
  name: "statistics",
  components: {
    searchFilter,
    BarChart
  },
  data() {
    return {
      toggle: true,
      headers: [
        {
          text: '№',
          align: 'start',
          filterable: true,
          value: 'product_id',
          class: "success--text subtitle-2 font-weight-bold",
          type: 'text'
        },
        {text: 'Наименование торгов', value: 'tender_name', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Наименование товара', value: 'product_name', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Начало торгов', value: 'start_date', class: "subtitle-2 font-weight-bold", type: 'datepicker'},
        {text: 'Начальная цена (руб.)', value: 'bidding_start', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Лучшая цена (руб.)', value: 'bidding_stop', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Количество', value: 'volume', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Ед. измерения', value: 'unit', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Участник с лучшей ценой', value: 'best_user', class: "subtitle-2 font-weight-bold", type: 'text'},
      ],
      statisticsMarketFilter: [],
      statisticsOfferFilter: [],
      statistics_market: [],
      statistics_offer: [],
      benefit_market: null,
      benefit_offer: null,
      loader: false,
      fuck_tender: [359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 372, 373, 374, 408, 409, 410, 411, 412, 418, 413, 414, 420, 424, 425, 427, 428, 429, 439, 440, 441, 442, 443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 461, 462, 463, 465, 466, 467, 470, 471, 472, 473, 474, 475, 512, 519, 520, 553, 540]
    }
  },
  async created() {
    const market = this.$store.getters.getMarketStatistic
    const offer = this.$store.getters.getOfferStatistic
    const statistics_market = this.$store.getters.getStatisticsMarketArray
    const statistics_offer = this.$store.getters.getStatisticsOfferArray
    if (market) this.benefit_market = market
    if (offer) this.benefit_offer = offer
    if (statistics_market) this.statistics_market = statistics_market
    if (statistics_offer) this.statistics_offer = statistics_offer
    await this.getPayload()
  },
  computed: {
    getUnits() {
      return this.$store.getters.getUnits
    }
  },
  watch: {
    getUnits(val) {
      if (val && val.length) {
        this.headers = this.headers.map(item => {
          if (item.value === 'unit') {
            item.type = 'select'
            item.items = val.map(i => ({
              title: i.title,
              value: i.title
            }))
          }
          return item
        })
      }
    }
  },
  methods: {
    async getPayload() {
      let STATIC_DATA = []
      if (!this.statistics_market.length || !this.statistics_offer.length) {
        this.loader = true
        STATIC_DATA = await this.$store.dispatch('getStatics')
      } else STATIC_DATA = this.$store.getters.getStatistics
      const USERS = await this.$store.getters.getUsersStatus
      if (STATIC_DATA.length && USERS.length) await this.statisticMutation(USERS, STATIC_DATA)
    },

    setSeparate(number) {
      return numberSeparator(+number)
    },

    filterMarketResult(data) {
      this.statisticsMarketFilter = data
    },

    filterOfferResult(data) {
      this.statisticsOfferFilter = data
    },

    getDate(date) {
      return moment.unix(date).format('LLL')
    },

    // 'tender_id' => $product->id,
    // 'tender_type' => $product->type,
    // 'tender_name' => $product->product,
    // 'tender_start_date' => $product->start_date,
    // 'tender_stop_date' => $product->stop_date,
    // 'tender_price' => $product->start_price,
    // 'tender_volume' => $product->volume,
    // 'tender_winner_id' => $product->winner_id,
    // 'multiproducts' => $multiproduts_query,
    // 'biddings' => $biddings_query,
    // 'multi_winners' => $multi_winners_query

    async statisticMutation(USERS, STATIC_DATA) {
      function Payload(
          variable,
          type,
          product_id,
          tender_name,
          product_name,
          start_date,
          stop_date,
          price,
          volume,
          unit = null,
          best_user,
          winners,
          bidding_start,
          bidding_stop,
          bidding_start_total,
          bidding_stop_total,
          benefit
      )
      {
        return {
          variable,
          type,
          product_id: product_id.toString(),
          tender_name,
          product_name,
          start_date,
          stop_date,
          price,
          volume,
          unit,
          best_user: best_user ? best_user.title : 'Нет',
          best_user_phone: best_user ? best_user.phone : '',
          winners,
          bidding_start: bidding_start ? bidding_start.toString() : 'Без начальной цены',
          bidding_stop: bidding_stop ? bidding_stop.toString() : 'Нет',
          bidding_start_total,
          bidding_stop_total,
          benefit
        }
      }

      // Сепарируем участника торгов по типу
      async function getUserSeparator(USERS_DATA, rate, volume) {
        let USER_RESULT = null
        if (USERS_DATA.corporations) {
          USER_RESULT = {
            title: USERS_DATA.corporations.inc,
            name: USERS_DATA.corporations.user,
            phone: USERS_DATA.corporations.phone,
            rate,
            volume
          }
        } else if (USERS_DATA.employers) {
          USER_RESULT = {
            title: `ИП ${USERS_DATA.employers.surname ? USERS_DATA.employers.surname : ''} ${USERS_DATA.employers.name ?
                USERS_DATA.employers.name[0].toUpperCase() + '.' : ''}${USERS_DATA.employers.patronymic ?
                USERS_DATA.employers.patronymic[0].toUpperCase() + '.' : ''}`,
            name: `${USERS_DATA.employers.surname ? USERS_DATA.employers.surname : ''} ${USERS_DATA.employers.name ?
                USERS_DATA.employers.name : ''} ${USERS_DATA.employers.patronymic ? USERS_DATA.employers.patronymic :
                ''}`,
            phone: USERS_DATA.employers.phone,
            rate,
            volume
          }
        }
        return USER_RESULT
      }

      // Собираем данные пользователя в зависимости от типа ИП или ЮР
      async function getUser(USERS_DATA, BIDDINGS, ID) {
        if (!USERS_DATA) return null
        // Ищем ставку пользователя
        const USERS_BIDDINGS = BIDDINGS.filter(item => item.user_id === +ID)
        const rate = USERS_BIDDINGS[USERS_BIDDINGS.length - 1].rate
        const volume = USERS_BIDDINGS[USERS_BIDDINGS.length - 1].volume
        return await getUserSeparator(USERS_DATA, rate, volume)
      }

      // Отдаем данные победителей (их ставки и данные) по каждому товару --- старый тип торгов
      async function getWinners(winner_id, biddings) {
        if (!winner_id) return null
        const USERS_DATA = USERS.find(item => +item.id === +winner_id)
        if (USERS_DATA) return await getUser(USERS_DATA, biddings, winner_id)
        else return null
      }

      // Собираем ставки сделанные по конкретному мультитовару
      async function getMultiBiddings(bidding, multi_id) {
        return bidding.filter(item => +item.multiproduct_id === +multi_id)
      }

      const NEW_STATICS = []

      // Бегаем по данным товаров и формируем новые данные для создания таблицы
      for (let statistic of STATIC_DATA) {
        if (!statistic.multiproducts.length) {

          const winners_data = await getWinners(+statistic.tender_winner_id, statistic.biddings)

          let VOLUME = this.fuck_tender.includes(+statistic.tender_id) ? parseInt(statistic.tender_volume) / 100 :
              parseInt(statistic.tender_volume)

          const bidding_start = statistic.biddings.length ? statistic.biddings[0].rate : null
          const bidding_stop = statistic.biddings.length ? statistic.biddings[statistic.biddings.length - 1].rate : null
          const best_user = statistic.biddings.length ?
              await getWinners(statistic.biddings[statistic.biddings.length - 1].user_id, statistic.biddings) : null
          const bidding_start_total = statistic.biddings.length ? bidding_start * VOLUME : null
          const bidding_stop_total = statistic.biddings.length ? bidding_stop * VOLUME : null
          const benefit = bidding_start_total && bidding_stop_total ?
              statistic.tender_type === 'market' ? bidding_start_total - bidding_stop_total :
                  bidding_stop_total - bidding_start_total : null

          NEW_STATICS.push(Payload(
              'ONE',
              statistic.tender_type,
              statistic.tender_id,
              statistic.tender_name,
              statistic.tender_name,
              +statistic.tender_start_date,
              +statistic.tender_stop_date,
              +statistic.tender_price,
              parseInt(statistic.tender_volume),
              null,
              best_user,
              winners_data ? [winners_data] : [],
              bidding_start,
              bidding_stop,
              bidding_start_total,
              bidding_stop_total,
              benefit
          ))
        } else {
          for (let multi of statistic.multiproducts) {

            const multi_bidding = await getMultiBiddings(statistic.biddings, multi.id)

            const multi_winners_data = []

            for (let item of statistic.multi_winners) {
              let res = await getWinners(item.user_id, statistic.biddings)
              multi_winners_data.push(res)
            }

            let VOLUME = multi.unit === '100 шт.' || this.fuck_tender.includes(+statistic.tender_id) ?
                parseInt(multi.volume) / 100 : parseInt(multi.volume)

            const bidding_start = multi_bidding.length ? multi_bidding[0].rate : null
            const bidding_stop = multi_bidding.length ? multi_bidding[multi_bidding.length - 1].rate : null
            const best_user = multi_bidding.length ?
                await getWinners(multi_bidding[multi_bidding.length - 1].user_id, multi_bidding) : null
            const bidding_start_total = statistic.biddings.length ? bidding_start * Math.ceil(VOLUME) : null
            const bidding_stop_total = statistic.biddings.length ? bidding_stop * Math.ceil(VOLUME) : null
            const benefit = bidding_start_total && bidding_stop_total ?
                statistic.tender_type === 'market' ? bidding_start_total - bidding_stop_total :
                    bidding_stop_total - bidding_start_total : null

            NEW_STATICS.push(
                Payload(
                    'MULTI',
                    statistic.tender_type,
                    +statistic.tender_id,
                    statistic.tender_name,
                    multi.name,
                    +statistic.tender_start_date,
                    +statistic.tender_stop_date,
                    +multi.price,
                    +multi.volume,
                    multi.unit,
                    best_user,
                    multi_winners_data,
                    bidding_start,
                    bidding_stop,
                    bidding_start_total,
                    bidding_stop_total,
                    benefit
                ))
          }
        }
      }


      // Разбиваем по типу торгов
      const STATICS_MARKET = []
      const STATICS_OFFER = []
      for (let item of NEW_STATICS) {
        if (item.type === 'market') STATICS_MARKET.push(item)
        else STATICS_OFFER.push(item)
      }

      this.statistics_market = STATICS_MARKET
      this.statistics_offer = STATICS_OFFER

      await this.$store.dispatch('setStatisticsMarket', STATICS_MARKET)
      await this.$store.dispatch('setStatisticsOffer', STATICS_OFFER)

      // Считаем бенефит market
      let BENEFIT_MARKET = 0
      console.groupCollapsed('Торги на понижение')
      for (let item of STATICS_MARKET) {
        if (typeof parseInt(item.benefit) === "number" && !isNaN(parseInt(item.benefit)) && +item.benefit) {
          +item.benefit > 1000000 ?
              console.groupCollapsed(`%c ${numberSeparator(parseInt(item.benefit))}`, 'color: red') :
              console.groupCollapsed(`%c ${numberSeparator(parseInt(item.benefit))}`, 'color: wight');

          console.table({
            '№': item.product_id,
            'Наименование торгов/товара': item.product_name,
            'Начальная цена': numberSeparator(item.bidding_start),
            'Конечная цена': numberSeparator(item.bidding_stop),
            'Начальная цена + объем': numberSeparator(item.bidding_start_total),
            'Конечная цена + объем': numberSeparator(item.bidding_stop_total),
            'Количество': numberSeparator(item.volume),
            'Ед.изм.': item.unit,
            'Мультиторги': item.variable === 'MULTI' ? 'Да' : 'Нет'
          })
          console.groupEnd();

          BENEFIT_MARKET = parseInt(item.benefit) + BENEFIT_MARKET
        }
      }
      console.log(`%c Итого: ${numberSeparator(BENEFIT_MARKET)} `,
          'background-color: blue; border-radius: 20px; color: wight')
      console.groupEnd()

      if (!this.benefit_market) {
        this.benefit_market = BENEFIT_MARKET
        await this.$store.dispatch('setMarket', BENEFIT_MARKET)
      }
      //this.benefit_market = this.benefit_market ? this.benefit_market : BENEFIT_MARKET

      // Считаем бенефит offer
      let BENEFIT_OFFER = 0
      console.groupCollapsed('Торги на повышение')
      for (let item of STATICS_OFFER) {
        if (typeof parseInt(item.benefit) === "number" && !isNaN(parseInt(item.benefit)) && +item.benefit) {
          +item.benefit > 1000000 ?
              console.groupCollapsed(`%c ${numberSeparator(parseInt(item.benefit))}`, 'color: red') :
              console.groupCollapsed(`%c ${numberSeparator(parseInt(item.benefit))}`, 'color: wight');

          console.table({
            '№': item.product_id,
            'Наименование торгов/товара': item.product_name,
            'Начальная цена': numberSeparator(item.bidding_start),
            'Конечная цена': numberSeparator(item.bidding_stop),
            'Начальная цена + объем': numberSeparator(item.bidding_start_total),
            'Конечная цена + объем': numberSeparator(item.bidding_stop_total),
            'Количество': numberSeparator(item.volume)
          })
          console.groupEnd();

          BENEFIT_OFFER = parseInt(+item.benefit) + BENEFIT_OFFER
        }


      }
      console.log(`%c Итого: ${numberSeparator(BENEFIT_OFFER)} `,
          'background-color: blue; border-radius: 20px; color: wight')
      console.groupEnd()

      if (!this.benefit_offer) {
        this.benefit_offer = BENEFIT_OFFER
        await this.$store.dispatch('setOffer', BENEFIT_OFFER)
      }
      //this.benefit_offer = this.benefit_offer ? this.benefit_offer : BENEFIT_OFFER

      this.loader = false
    },

    async docx(type) {
      if (type === 'market' && !this.statistics_market.length) {
        alert('[Ошибка!] Нет данных для формирования документа для торгов на повышение!')
        return
      }

      if (type === 'offer' && !this.statistics_market.length) {
        alert('[Ошибка!] Нет данных для формирования документа для торгов на понижение!')
        return
      }

      let Payload = type === 'market' ? this.statisticsMarketFilter : this.statisticsOfferFilter

      const base64 = await report(Payload, type)
      const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const blob = b64toBlob(base64, contentType)
      let fileName = type === 'market' ? 'торги на понижение' : 'торги на повышение'
      await saveAs(blob, `Отчет - ${fileName}.docx`);
    },
  }
}
</script>

<style scoped>
.box {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  transition: .3s;
}

.title {
  font-size: 25px;
  font-weight: bold;
  color: #2477cc;
  text-transform: uppercase;
}

.subtitle {
  font-weight: lighter;
  margin-bottom: 20px;
}

.price {
  color: #3dd091;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 25px;
}

.vertical__line {
  position: relative;
  margin: 0;
  padding: 0;
  top: 2px;
  left: 50px;
  border-left: 2px solid #9595ff;
  height: 60px;
  transition: .3s;
  display: inline-block
}
</style>
