import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCardText,[_c(VDataTable,{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.winners,"loading":_vm.loader,"loading-text":"Загрузка... Ожидайте!"},scopedSlots:_vm._u([{key:"item.multiproduct_id",fn:function({item}){return [_c('b',[_vm._v(_vm._s(_vm.getProduct(item.multiproduct_id)))])]}},{key:"item.user_id",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.getName(item.user_id))+" ")]}},{key:"item.contract",fn:function({item}){return [_c('b',{style:(_vm.getContract(item.contract) === 'Заключен' ? 'color: green' : 'color: red')},[_vm._v(_vm._s(_vm.getContract(item.contract)))])]}},{key:"item.timestamp",fn:function({item}){return [_c('b',[_vm._v(_vm._s(_vm.getDate(item.timestamp)))])]}}])})],1),_c(VDivider),_c(VCardActions,[_c('div'),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Закрыть ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }