<template>
  <div>
    <p style="font-weight: bold; text-align: center" :class="socketStatus ? 'event__green__attention' : 'event__red__attention'" v-if="socketText">{{ socketText}}</p>
    <div class="card__default">
      <div>
          <!--  Назначены, идут, завершены  -->
        <h5>УВЕДОМЛЕНИЯ</h5>
        <UserDownloadDocs />
      </div>
    </div>
    <div class="card__default">
      <MathUsersINN />
    </div>
  </div>
</template>

<script>
import MathUsersINN from "@/components/events/Attention/MathUsersINN";
import UserDownloadDocs from "@/components/events/Attention/UserDownloadDocs";
import axios from "axios";
import moment from "moment";
export default {
  name: 'Attention',
  components: {
    MathUsersINN,
    UserDownloadDocs
  },
  created() {
    this.getSocketStatus()
    this.getInterval = setInterval(()=> this.getSocketStatus(), 60000 )
  },
  destroyed() {
    clearInterval(this.getInterval)
    this.getInterval = null
  },
  data() {
    return {
      socketData: Object,
      socketStatus: true,
      socketText: '',
      getInterval: null
    }
  },
  watch: {
    socketStatus(val) {
      if (val) this.socketText = 'WEBSOCKET ПОДКЛЮЧЕН! ТОРГИ ИДУТ ПО СТАНДАРТНОЙ СХЕМЕ!'
      else this.socketText = 'WEBSOCKET ОТКЛЮЧЕН! ТОРГИ ИДУТ ПО РЕЗЕРВНОЙ СХЕМЕ!'
    }
  },
  methods: {
    async getSocketStatus() {
      const socket_result = await axios.get('/get-socket-status')
      if (socket_result && socket_result.status === 200) {
        this.socketData = socket_result.data
        this.socketStatus = this.socketData.status
      }
    }
  }

}
</script>
