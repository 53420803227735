<template>
  <div>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="winners"
          :loading="loader"
          loading-text="Загрузка... Ожидайте!"
          style="cursor: pointer"
      >
        <template v-slot:item.multiproduct_id="{item}">
          <b>{{ getProduct(item.multiproduct_id) }}</b>
        </template>
        <template v-slot:item.user_id="{item}">
          {{ getName(item.user_id) }}
        </template>
        <template v-slot:item.contract="{item}">
          <b :style="getContract(item.contract) === 'Заключен' ? 'color: green' : 'color: red'">{{ getContract(item.contract) }}</b>
        </template>
        <template v-slot:item.timestamp="{item}">
          <b>{{ getDate(item.timestamp) }}</b>
        </template>

      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <div></div>
      <v-spacer></v-spacer>
      <v-btn
          color="blue darken-1"
          text
          @click="closeModal"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: 'winners',
  props: {
    product: null
  },
  data() {
    return {
      headers: [
        {
          text: 'Наименование',
          align: 'start',
          filterable: true,
          value: 'multiproduct_id',
          class: "success--text subtitle-2 font-weight-bold"
        },
        {text: 'Победитель', value: 'user_id', class: "subtitle-2 font-weight-bold"},
        {text: 'Договор', value: 'contract', class: "subtitle-2 font-weight-bold"},
        {text: 'Дата', value: 'timestamp', class: "subtitle-2 font-weight-bold"},
      ],
      winners: [],
      loader: true
    }
  },
  async created() {
    if (this.product) {
      if (this.product.winners.length) this.winners = this.product.winners
      this.loader = false
    }
  },
  destroyed() {
    this.winners = []
  },
  methods: {
    getDate(date) {
      return moment.unix(date).format('LLL')
    },
    getProduct(id) {
      if (this.product.multiproducts) {
        const multiproduct = this.product.multiproducts.find(item=>item.id === id)
        if (multiproduct) return multiproduct.name
        else return '-'
      }
      else return '-'
    },
    getName(id) {
      const Users = this.$store.getters.getUsersStatus
      if (Users.length) {
        const User = Users.find(item=>+item.id === +id)
        if (User) {
          if (User.corporations) return User.corporations.inc
          else if (User.employers) return `ИП ${User.employers.surname} ${User.employers.name} ${User.employers.patronymic}`
          else return 'Не опредлен'
        }
      }
      else return 'Не опредлен'
    },
    getContract(contract) {
      return +contract ? 'Заключен' : 'Не заключен'
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
