<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="1200"
    >
      <v-card :loading="loader">
        <v-card-title>
          <span class="text-h5" v-if="getStoreSecurity">Сотрудник СБ:
            <b>{{ security_name }}</b></span>
          <span class="text-h5" v-else>Добавление сотрудника</span>
        </v-card-title>

        <template v-if="getStoreSecurity">
          <v-card-subtitle>
            <v-btn-toggle
                v-model="menu"
                color="teal"
                group
                class="xs-buttons"
            >
              <v-btn value="data">
                Данные
              </v-btn>
              <v-btn value="session" v-if="security_id">
                Сессии
              </v-btn>
            </v-btn-toggle>
          </v-card-subtitle>
        </template>

        <template v-if="dialog && menu==='data'">
          <v-card-text>

            <v-text-field
                v-model="security_name"
                label="ФИО сотрудника СБ"
                outlined
                required
                :error-messages="getErrors('security_name', $v.security_name)"
            ></v-text-field>
            <v-text-field
                v-model="security_login"
                label="Логин (email)"
                outlined
                required
                :error-messages="getErrors('security_login', $v.security_login)"
            ></v-text-field>
            <v-text-field
                v-if="!security_id"
                v-model="password"
                label="Пароль"
                outlined
                required
                :error-messages="getErrors('password', $v.password)"
            ></v-text-field>
            <v-select
                v-model="statusSecurity"
                :items="['Активен', 'Заблокирован']"
                label="Статус оператора"
                outlined
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <div class="text-center">
              <v-menu
                  top
                  close-on-click
                  v-if="this.security_id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="error"
                      dark
                      text
                      v-bind="attrs"
                      v-if="getStoreSecurity"
                      v-on="on"
                      :disabled="loader"
                  >
                    УДАЛИТЬ
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-btn
                        color="success"
                        text
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="deleteSecurity"
                    >
                      Удалить
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
            >
              Закрыть
            </v-btn>
            <div>
              <v-btn
                  v-if="getStoreSecurity && getStoreSecurity.id"
                  color="blue darken-1"
                  text
                  @click="update"
                  :disabled="loader"
              >
                Изменить
              </v-btn>
              <v-btn
                  v-else
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="loader"
              >
                Сохранить
              </v-btn>
            </div>

          </v-card-actions>
        </template>

        <template v-if="menu === 'session' && security_id">
          <v-card-text>
            <session :id="security_id"/>
          </v-card-text>
        </template>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators"
import session from "@/components/dialogs/security/session";

export default {
  name: "securityModal",
  components: {
    session
  },
  data: () => ({
    dialog: false,
    security: [],
    security_id: null,
    security_name: '',
    security_login: '',
    moderation: 1,
    password: '',
    statusSecurity: 'Активен',
    uniqEmail: true,
    loader: false,
    menu: 'data'
  }),
  validations() {
    const validations = {
      security_name: {required},
      security_login: { required, email, isUnique: this.isUnique },
      password: {required, minLength: minLength(6)}
    }
    return validations
  },
  beforeDestroy() {
    this.$store.dispatch('modalActionSecurity', null)
  },
  computed: {
    getSecurityData() {
      return this.$store.getters.getSecurityData
    },
    getStoreSecurity() {
      return this.$store.getters.getSecurityModal
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getStoreSecurity(val) {
      if (val) {
        this.dialog = true
        this.security_id = +val.id || ''
        this.security_name = val.name || ''
        this.security_login = val.login || ''
        this.password = val.password || ''
        this.moderation = +val.moderation || 1
        this.statusSecurity = +val.moderation ? 'Активен' : val.id ? 'Заблокирован' : 'Активен'
      } else {
        this.security_name = ''
        this.security_login = ''
        this.statusSecurity = 'Активен'
      }
    },
    dialog(val) {
      if (!val) {
        this.password = ''
        this.security_login = ''
        this.moderation = 1
        this.$store.dispatch('modalActionSecurity', null)
        this.menu = 'data'
      }
    },
    statusSecurity(val) {
      this.moderation = val === 'Активен' ? 1 : 0
    },
    getSecurityData(val) {
      this.security = val
    }
  },
  methods: {
    isUnique(value) {
      let allLogins = []
      for (let item of this.security) {
        if (+item.id !== this.security_id) allLogins.push(item.login)
      }
      if (allLogins.includes(value)) return false
      else return true
    },
    closeModal() {
      this.dialog = false
      this.$store.dispatch('modalActionSecurity', null)
    },
    async save() {
      if (this.$v.$invalid) {
        console.error('Error validation: ', this.$v)
        this.$v.$touch();
        return;
      }
      let payload = {
        name: this.security_name,
        login: this.security_login,
        password: this.password,
        moderation: this.moderation
      }
      this.loader = true
      try {
        let res = await this.$store.dispatch('setSecurity', payload)
        if (res && res.status === 200) {
          await this.$store.dispatch('getSecurity')
          this.dialog = false
        }
      } catch (error) {
        console.error('error of save new security', error)
      }
      this.loader = false
    },
    async update() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (!this.getStoreSecurity) {
        console.error('Empty security data id')
        return
      }
      let payload = {
        id: this.security_id,
        name: this.security_name,
        login: this.security_login,
        moderation: this.moderation
      }
      this.loader = true
      let res = await this.$store.dispatch('updateSecurity', payload)
      if (res && res.status === 200) {
        this.dialog = false
        await this.$store.dispatch('getSecurity')
      }
      this.loader = false
    },
    async deleteSecurity() {
      let payload = {
        id: this.getStoreSecurity.id,
      }
      let res = await this.$store.dispatch('deleteSecurity', payload)
      if (res && res.status === 200) {
        this.dialog = false
        await this.$store.dispatch('getSecurity')
      } else console.error('ERROR IN deleteSecurity')
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors
      switch (name) {
        case "security_name":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "security_login":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          !model.email ? errors.push("Логин должен быть Email!") : ""
          !model.isUnique ? errors.push("Email не уникальный") : ""
          break;
        case "password":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.minLength ? errors.push("Допускается не менее 6-ти символов!") : ""
          break;
        default:
          break;
      }
      return errors
    },
  }
}
</script>

<style scoped>

</style>
