import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        security: []
    }),
    mutations: {
        modalMutation(state, data) {
            state.modal = data || null
        },
        securityMutations(state, data) {
            state.security = data || []
        }
    },
    actions: {
        modalActionSecurity({commit}, data) {
            commit('modalMutation', data)
        },
        async getSecurity({commit}) {
            let res = await axios('/admin-all-security', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                }
            })
            if (res && res.status === 200) {
                commit('securityMutations', res.data)
                return res.data
            }
            else console.error('ERROR in getSecurity')
        },
        setSecurity({}, payload) {
            return axios('/admin-set-security', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        updateSecurity({}, payload) {
            return axios('/admin-update-security', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteSecurity({}, payload) {
            return axios('/admin-delete-security', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        setSession({}, payload) {
            return axios('/set-security-session', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        async getSecuritySession({}, payload) {
            const res =  await axios('/get-security-session',  {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) return res.data
            else return []
        }
    },
    getters: {
        getSecurityModal: state => state.modal,
        getSecurityData: state => state.security
    }
}
