<template>
  <div>
    <v-row>
      <v-col md="6">
        <Info/>
        <Config/>
        <UpdatePassword/>
      </v-col>
      <v-col md="6">
<!--        <Images/>-->
        <Departments/>
        <Initiator/>
        <Units/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Info from "@/components/config/Info";
import Images from "@/components/config/Images";
import Departments from "@/components/config/Departments";
import Units from "@/components/config/Units";
import Initiator from "@/components/config/Initiator";
import UpdatePassword from "@/components/config/UpdatePassword";
import Config from "@/components/config/AdminConfig";

export default {
  name: "config",
  components: {
    Units,
    Info,
    Images,
    Departments,
    UpdatePassword,
    Initiator,
    Config
  }
}
</script>

<style scoped>

</style>
