import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Удаление ед. измерения")])]),_c(VCardText,[_vm._v(" Вы уверенны что хотите удалить: "),_c('b',[_vm._v(_vm._s(_vm.unitName))]),_vm._v(" ? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" Нет ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.delUnit}},[_vm._v(" Удалить ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }