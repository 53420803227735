import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        units: []
    }),
    mutations: {
        modalMutationUnit(state, data) {
            state.modal = data || null
        },
        unitsMutations(state, data) {
            state.units = data || []
        }
    },
    actions: {
        modalActionUnit({commit}, data) {
            commit('modalMutationUnit', data)
        },
        async getUnits({commit}) {
            try {
                let res = await axios.post('/admin-all-units')
                if (res && res.status === 200) {
                    let units = res.data
                    if (units.length) units.map(item => item.show = true)
                    commit('unitsMutations', units)
                }
                else console.error('Не удалось получить данные от /admin-all-units')
            }
            catch (e) {
                console.error('ERROR IN getUnits ', e)
            }
        },
        setUnit({}, payload) {
            return axios('/admin-set-unit', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        updateUnit({}, payload) {
            return axios('/admin-update-unit', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteUnit({}, payload) {
            return axios('/admin-delete-unit', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        }
    },
    getters: {
        getUnitsModal: state => state.modal,
        getUnits: state => state.units
    }
}