<template>
  <div>
    <div class="card__default">
      <searchFilter v-if="users.length" :headers="headers" :payload="users" :query="queryParams" @filter="filterResult"/>
<!--      <pre>{{ users }}</pre>-->
      <v-data-table
          :headers="headers"
          :items="usersFilter"
          @click:row="handleClick"
          style="cursor: pointer"
      >
        <template v-slot:item.name="{item}">
          <div style="font-weight: 600; position: relative">
            {{ item.name }}
            <div
                v-if="Number(item.date) + (3 * 24 * 60 * 60 * 1000) > new Date().getTime()"
                style="background-color: rgb(255,190,190); padding: 2px 10px; border-radius: 10px; font-size: 10px;
                display: inline-block; margin-bottom: 5px">
              Новый поставщик
            </div>
          </div>
        </template>
        <template v-slot:item.email="{item}">
          <div v-if="!parseInt(item.email_incorrect)">{{ item.email }}</div>
          <div v-else class="custom__chips">{{ item.email }}</div>
        </template>
        <template v-slot:item.docs="{item}">
          <div v-if="item.docs === 'Комплект'">
            <div v-if="+item.moderation === 0" class="chips__default">{{ item.docs }}</div>
            <div v-else style="color: green">{{ item.docs }}</div>
          </div>
          <div v-else style="color: red">{{ item.docs }}</div>
        </template>
        <template v-slot:item.winners_status="{item}">
          {{ item.winners_length }}
        </template>
        <template v-slot:item.contract_status="{item}">
          {{ item.contract_length }}
        </template>
        <template v-slot:item.status="{item}">
          <div v-html="getStatus(item.status)"></div>
        </template>
        <template v-slot:item.moderation="{item}">
          <div v-html="getModeration(item.moderation)"></div>
        </template>
      </v-data-table>
    </div>
    <user @user="userMutation" @data="userData"/>
  </div>
</template>

<script>
import user from '@/components/dialogs/user/index.vue'
import searchFilter from "@/components/searchFilter";

export default {
  name: "users",
  components: {user, searchFilter},
  data() {
    return {
      headers: [
        {
          text: 'Участник',
          align: 'start',
          filterable: true,
          value: 'name',
          class: "success--text subtitle-2 font-weight-bold",
          type: 'text'
        },
        {text: 'ID', value: 'id', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Контактное лицо', value: 'user', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Телефон', value: 'phone', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Email', value: 'email', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'ИНН', value: 'inn', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'ОГРН/ИП', value: 'ogrn', class: "subtitle-2 font-weight-bold", type: 'text'},
        {text: 'Документы', value: 'docs', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            { title: 'Комплект', value: 'Комплект' },
            { title: 'Не комплект', value: 'Не комплект' }
          ]
        },
        {text: 'Победы', value: 'winners_status', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            { title: 'Есть победы', value: '1' },
            { title: 'Не побед', value: '0' }
          ]
        },
        {text: 'Договора', value: 'contract_status', class: "subtitle-2 font-weight-bold", type: 'select',
          items: [
            { title: 'Заключены', value: '1' },
            { title: 'Не заключены', value: '0' }
          ]
        },
        {
          text: 'Состояние', value: 'status', class: "subtitle-2 font-weight-bold",
          type: 'select',
          items: [
            {title: 'Действующая', value: 'ACTIVE'},
            {title: 'Ликвидируется', value: 'LIQUIDATIN'},
            {title: 'Ликвидирована', value: 'LIQUIDATED'},
            {title: 'Банкротство', value: 'BANKRUPT'},
            {title: 'Присоединение с ликвидацией', value: 'REORGANIZING'},
            {title: 'Не определен', value: '__null__'}
          ]
        },
        {
          text: 'Статус', value: 'moderation', class: "subtitle-2 font-weight-bold", type: 'select', items:
              [
                {title: 'Модерация', value: '0'},
                {title: 'Активно', value: '1'},
                {title: 'Заблокирован', value: '2'}
              ]
        }
      ],
      users: [],
      usersFilter: [],
      queryParams: null,
      table: false,
      status: [
        {ACTIVE: 'Действующая', color: 'green'},
        {LIQUIDATIN: 'Ликвидируется', color: 'red'},
        {LIQUIDATED: 'Ликвидирована', color: 'red'},
        {BANKRUPT: 'Банкротство', color: 'red'},
        {REORGANIZING: 'В процессе присоединения к другому юрлицу, с последующей ликвидацией', color: 'red'}
      ],
    }
  },
   async mounted() {
     this.queryParams = this.$route.query || null;
     await this.addDataInPayload()
  },
  methods: {
    async addDataInPayload() {
      let users = await this.$store.dispatch('getUsers')
      //let products = await this.$store.dispatch('getProducts')
      for (let item of users) {
        item.name = await this.getUserNameOfType(item)
        item.user = await this.getUser(item)
        item.phone = await this.getPhone(item)
        item.inn = await this.getRegData(item).inn
        item.ogrn = await this.getRegData(item).ogrn
        item.docs = this.getDocs(item)

        if ( item.winner && item.winner.length ) {
          item.winners_length = String(item.winner.length)
          item.winners_status = '1'
          item.contract_length = String(item.winner.reduce(function (currentSum, currentNumber) {
            return currentSum + Number(currentNumber.contract)
          }, 0))
        }
        else {
          item.winners_length = item.winners_status = item.contract_length = '0'
        }
        item.contract_status = item.winner && item.contract_length && +item.contract_length >= 1 ? '1' : '0'

      }
      this.users = this.usersFilter= users
    },
    // async getProfit(products) {
    //   console.log('getProfit ', products)
    // },
    getUserNameOfType(data) {
      //if (!data || !data.corporations || !data.employers) return

      if (data.type === 'employers' && data.employers) {
        let {surname, name, patronymic} = data.employers
        let Surname = surname ? surname : ''
        let Name = name ? name : 'Имя не указано'
        let Patronymic = patronymic ? patronymic : ''
        return `ИП ${Surname} ${Name} ${Patronymic}`
      } else if (data.type === 'corporations' && data.corporations) {
        return data.corporations.inc
      } else {
        return 'Нет данных'
      }
    },
    getUserType(type) {
      if (type === 'employers') {
        return 'Предприниматель'
      } else if (type === 'corporations') {
        return 'Компания'
      } else return 'Тип не определен'
    },
    getUser(data) {
      if (data.type === 'employers') {
        return 'нет'
      } else if (data.type === 'corporations') {
        let {user} = data.corporations
        return user || 'нет'
      } else return 'Noname'
    },
    getPhone(data) {
      if (data.type === 'employers') {
        if (!data.employers) return
        let {phone} = data.employers
        return phone ? phone : '-'
      } else if (data.type === 'corporations') {
        let {phone} = data.corporations
        return phone || '-'
      } else return 'Noname'
    },
    getRegData(data) {
      if (data.type === 'employers') {
        if (!data.employers) return
        let {inn, ogrnip} = data.employers
        return { inn, ogrn: ogrnip } // `<div><b>ИНН:</b> ${inn}</div><div><b>ОГРНИП:</b> ${ogrnip}</div>`
      } else if (data.type === 'corporations') {
        let {inn, ogrn} = data.corporations
        return { inn, ogrn } //`<div><b>ИНН:</b> ${inn}</div><div><b>ОГРН:</b> ${ogrn}</div>`
      } else return 'Noname'
    },
    getStatus(status) {
      if (status && status !== 'null') {
        let statusRes = 'Не определено'
        for (let item of this.status) {
          let st = item[status]
          if (st) statusRes = `<div style="color: ${item.color}">${st}</div>`
        }
        return statusRes
      } else return '<div style="color: rgba(204,110,10,0.66)">Не определен</div>'
    },
    getModeration(moderation) {
      if (+moderation === 1) return '<div style="color:green">Активно</div>'
      else if (+moderation === 2) return '<div style="color:red">Заблокирован</div>'
      else return '<div style="color:#fb8c00">Модерация</div>'
    },
    handleClick(data) {
      this.$store.dispatch('modalActionUser', data)
    },
    getDocs(item) {
      if (item.corporations) {
        if (item.corporations.director && item.corporations.doverenost && item.corporations.registr &&
            item.corporations.reshenie && item.corporations.uchet && item.corporations.ustav) {
          if (+item.moderation === 0) return 'Комплект'
          else return 'Комплект'
        } else return 'Не комплект'
      } else if (item.employers) {
        if (item.employers.passport && item.employers.uchet && item.employers.registr) {
          if (+item.moderation === 0) return 'Комплект'
          else return 'Комплект'
        } else return 'Не комплект'
      }
    },
    userMutation(user) {
      if (!this.users && !this.users.length) return
      let userIDX = this.users.findIndex(item => +item.id === +user.user_id)
      if (!userIDX) return
      if (user.user_type === 'corporations') this.users[userIDX].corporations = user.data
      else this.users[userIDX].employers = user.data
    },
    userData() {
      this.addDataInPayload()
    },
    filterResult(data) {
      this.usersFilter = data
    }
  }
}
</script>

<style scoped>

</style>
