<template>
  <div>
    <div v-if="!toggle" class="d-flex justify-space-between  bg-surface-variant">
      <div></div>
      <v-btn class="mb-5" variant="outlined" color="#A5D6A7" @click="toggleAction">
        <v-icon dark>
          mdi-filter
        </v-icon>
        Поиск / фильтр
      </v-btn>
    </div>

    <div v-else>
      <div class="d-flex justify-space-between bg-surface-variant mb-8">
        <h4>Фильтр</h4>
        <v-btn density="compact" color="red" dark small @click="toggleAction">
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-wrap align-center">
        <div v-for="(head, i) in headers" :key="i">
          <v-text-field
              v-if="head.type === 'text'"
              v-model="search[head.value].val"
              :label="head.text"
              required
              hide-details
              class="mb-5 mr-5"
              clearable
              outlined
          ></v-text-field>
          <v-select
              v-if="head.type === 'select'"
              v-model="search[head.value].val"
              class="mb-5 mr-5"
              :label="head.text"
              :items="head.items"
              item-text="title"
              item-value="value"
              hide-details
              clearable
              single-line
              outlined
          ></v-select>
          <div v-if="head.type === 'datepicker'" style="margin-bottom: 20px; margin-right: 20px; min-width: 370px">
            <VueCtkDateTimePicker
                v-model="search[head.value].val"
                id="VueCtkDateTimePicker-1"
                :no-leabel="true"
                :header="false"
                locale="ru"
                label="Временной диапазон торгов"
                range
                :no-shortcuts="true"
                format="X"
                formatted="LLL"
                time-zone="Europe/Moscow"
                :no-button-now="true"
                :noClearButton="false"
                :right="true"
            />
          </div>

        </div>
      </div>
    </div>

    <v-divider class="mb-3 mt-8"></v-divider>
  </div>

</template>

<script>

export default {
  name: 'searchFilter',
  props: {
    headers: Array,
    payload: Array,
    query: Object
  },
  data() {
    return {
      toggle: false,
      search: {},
      itemsArray: JSON.parse(JSON.stringify(this.payload)) || [],
      activeField: null
    }
  },
  watch: {
    search: {
      handler(val) {
        this.Filtered(val)
      },
      deep: true
    },
  },
  created() {
    this.clearFilter()
  },
  mounted() {
    if (this.query && this.query.operator) {
      this.search['corporations.user'].val = this.query.operator
      this.toggle = true
    }
    if (this.query && this.query.docs) {
      this.search['docs'].val = this.query.docs
      this.toggle = true
    }
    if (this.query && this.query.moderation) {
      this.search['moderation'].val = this.query.moderation
      this.toggle = true
    }
  },
  methods: {
    toggleAction() {
      this.toggle = !this.toggle
      this.clearFilter()
    },
    clearFilter() {
      if (this.headers.length) {
        for (let item of this.headers) {
          this.$set(this.search, item.value, {val: '', type: item.type, name: item.value})
        }
      }
    },
    includesNumber(item, search) {
      return item % search === 0;
    },
    Filtered() {
      let matchesArray = []
      for (let item of this.payload) {
        let matches = true
        for (let key in this.search) {
          // Собираем все объекты с точкой
          let moreKey = this.search[key].name.split('.')
          // Поиск по текстовым полям с вхождением каждого символа
          if (this.search[key].val && this.search[key].type === 'text') {
            if (moreKey.length && moreKey.length === 2) {
              matches =
                  item[moreKey[0]][moreKey[1]].toLowerCase().trim().includes(this.search[key].val.toLowerCase().trim())
            } else if (item[key] && typeof item[key] === 'string') {
              matches = item[key].toLowerCase().trim().includes(this.search[key].val.toLowerCase().trim())
            } else if (item[key] && typeof item[key] === 'number') {
              matches = item[key] === +this.search[key].val
            }
          }

          // Посиск по селектам с прямым вхождением
          if (this.search[key].val && item[key] && this.search[key].type === 'select') {
            matches = item[key].toLowerCase() === this.search[key].val.toLowerCase()
          }
          if (this.search[key].val && !item[key] && this.search[key].type === 'select') matches =
              this.search[key].val === '__null__'

          // Поиск по datepicker (временному диапазону)
          if (this.search[key].val && this.search[key].val.start && this.search[key].val.end && this.search[key].type
              === 'datepicker') {
            const start = Number(this.search[key].val.start)
            const stop = Number(this.search[key].val.end)
            const start_date = Number(item.start_date)
            const stop_date = Number(item.stop_date)
            matches = start < start_date && stop > stop_date
          }

          if (!matches) break
        }
        if (matches) matchesArray.push(item)
      }

      this.itemsArray = matchesArray
      this.$emit('filter', this.itemsArray)
    },
  }
}
</script>
