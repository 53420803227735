<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="1200"
    >
      <v-card :loading="loader">
        <v-card-title>
          <span class="text-h5" v-if="getStoreOperators && getStoreOperators.corporations">Оператор: <b>{{
              operator
            }}</b></span>
          <span class="text-h5" v-else>Добавление оператора</span>
        </v-card-title>

        <template v-if="getStoreOperators && getStoreOperators.corporations">
          <v-card-subtitle>
              <v-btn-toggle
                  v-model="menu"
                  color="teal"
                  group
                  class="xs-buttons"
              >
                <v-btn value="data">
                  Данные
                </v-btn>
                <v-btn value="session">
                  Сессии
                </v-btn>
              </v-btn-toggle>
          </v-card-subtitle>
        </template>

        <template v-if="dialog && menu==='data'">
          <v-card-text>

              <v-text-field
                  :disabled="adminStatus"
                  v-model="operator"
                  label="Оператор"
                  outlined
                  required
                  :error-messages="getErrors('operator', $v.operator)"
              ></v-text-field>
              <v-select
                  :disabled="adminStatus"
                  v-model="selectDep"
                  :items="departments"
                  item-text="title"
                  item-value="id"
                  label="Отдел не выбран"
                  outlined
                  :error-messages="getErrors('select', $v.selectDep)"
              ></v-select>
              <v-text-field
                  :disabled="adminStatus"
                  v-model="tel"
                  v-mask="'+7 (###) ###-##-##'"
                  label="Телефон"
                  outlined
                  required
                  :error-messages="getErrors('tel', $v.tel)"
              ></v-text-field>
              <v-text-field
                  :disabled="adminStatus"
                  v-model="email"
                  label="Email"
                  outlined
                  required
                  :error-messages="getErrors('email', $v.email)"
              ></v-text-field>
              <div v-if="getStoreOperators && getStoreOperators.corporations"></div>
              <v-text-field
                  :disabled="adminStatus"
                  v-else
                  v-model="password"
                  label="Пароль"
                  outlined
                  required
                  :error-messages="getErrors('password', $v.password)"
              ></v-text-field>
              <v-select
                  :disabled="adminStatus"
                  v-if="getStoreOperators && getStoreOperators.corporations"
                  v-model="statusOperator"
                  :items="['Активен', 'Заблокирован']"
                  label="Статус оператора"
                  outlined
              ></v-select>
          </v-card-text>
          <v-card-actions>
            <div class="text-center" v-if="!adminStatus">
              <v-menu
                  top
                  close-on-click
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="error"
                      dark
                      text
                      v-bind="attrs"
                      v-if="getStoreOperators && getStoreOperators.corporations"
                      v-on="on"
                      :disabled="loader"
                  >
                    УДАЛИТЬ
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-btn
                        color="success"
                        text
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="deleteOperator"
                    >
                      Удалить
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
            >
              Закрыть
            </v-btn>
            <div v-if="!adminStatus">
              <v-btn
                  v-if="getStoreOperators && getStoreOperators.corporations"
                  color="blue darken-1"
                  text
                  @click="update"
                  :disabled="loader"
              >
                Изменить
              </v-btn>
              <v-btn
                  v-else
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="loader"
              >
                Сохранить
              </v-btn>
            </div>

          </v-card-actions>
        </template>

        <template v-if="menu === 'session'">
          <v-card-text>
              <session :id="operator_id"/>
          </v-card-text>
        </template>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators"
import session from "@/components/dialogs/operator/session";

export default {
  name: "operator",
  components: {
    session,
  },
  data: () => ({
    dialog: false,
    operator_id: null,
    operator: '',
    tel: '',
    email: '',
    password: '',
    statusOperator: 'Активен',
    departments: [],
    selectDep: null,
    owner: null,
    uniqEmail: true,
    users: [],
    products: [],
    loader: false,
    menu: 'data'
  }),
  validations() {
    const validations = {
      operator: {required},
      tel: {required},
      email: {required, email, isUnique: this.isUnique},
      password: {required, minLength: minLength(6)},
      selectDep: {required}
    }
    // if (!this.getStoreOperators.corporations) {
    //   validations.password = {required, minLength: minLength(6)}
    // }
    return validations
  },
  created() {
    this.departments = this.getDeps
    this.owner = this.getOwner
  },
  beforeDestroy() {
    this.$store.dispatch('modalActionOperators', null)
  },
  computed: {
    getOperatorsData() {
      return this.$store.getters.getOperatorsData
    },
    getStoreOperators() {
      return this.$store.getters.getOperatorsModal
    },
    getDeps() {
      return this.$store.getters.getDepartaments
    },
    getOwner() {
      return this.$store.getters.getOwner
    },
    getUsers() {
      return this.$store.getters.getUsersStatus
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getStoreOperators(val) {
      if (val) {
        this.dialog = true
        this.operator_id = +val.id
        this.operator = val.corporations && val.corporations.user ? val.corporations.user : ''
        this.tel =
            val.corporations && val.corporations.phone ? val.corporations.phone : this.owner ? this.owner.phone : ''
        this.selectDep = val.department_id ? val.department_id : null
        this.password = val.password
        this.email = val.email ? val.email : ''
        this.statusOperator = +val.moderation ? 'Активен' : 'Заблокирован'
        this.products = val.products || []
      } else {
        this.operator = ''
        this.tel = ''
        this.email = ''
        this.selectDep = null
        this.statusOperator = 'Активен'
        this.products = []
      }
    },
    dialog(val) {
      if (!val) {
        this.selectDep = null
        this.password = ''
        this.$store.dispatch('modalActionOperators', null)
        this.menu = 'data'
      }
    },
    getDeps(val) {
      this.departments = val
    },
    getOwner(val) {
      this.owner = val
    },
    getUsers(val) {
      this.users = val
    }
  },
  methods: {
    isUnique(value) {
      let uniqOperEmails = this.getOperatorsData.filter(item => item.email === value && item.id !== this.getStoreOperators.id)
      let unisUsersEmails = this.users.filter(item => item.email === value)
      if (uniqOperEmails.length || unisUsersEmails.length) return false
      else return true
    },
    async save() {
      if (this.$v.$invalid) {
        console.error('Error validation: ', this.$v)
        this.$v.$touch();
        return;
      }
      if (!this.owner) {
        alert('Нет данных владельца торговой площадки...')
        return
      }
      let payload = {
        //...user
        email: this.email,
        password: this.password,
        department_id: this.selectDep,
        //....corporations
        user: this.operator,
        phone: this.tel,
        //.
        ogrn: this.owner.ogrn,
        inn: this.owner.inn,
        legalform: this.owner.legalform,
        inc: this.owner.inc,
        date: new Date() / 1000
      }
      this.loader = true
      try {
        let res = await this.$store.dispatch('setOperator', payload)
        if (res && res.status === 200) {
          await this.$store.dispatch('getOperators')
          this.dialog = false
        }
      } catch (error) {
        console.error('error of save new operator', error)
      }
      this.loader = false
    },
    async update() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (!this.getStoreOperators || !this.getStoreOperators.corporations) {
        console.error('Empty operators data id')
        return
      }
      let payload = {
        //...data
        user_id: this.getStoreOperators.id,
        corp_id: this.getStoreOperators.corporations.id,
        //...user
        email: this.email,
        department_id: this.selectDep,
        moderation: this.statusOperator === 'Активен' ? 1 : 0,
        password: this.password,
        //....corporations
        user: this.operator,
        phone: this.tel,
      }
      this.loader = true
      let res = await this.$store.dispatch('updateOperators', payload)
      if (res && res.status === 200) {
        if (res.data === 'Email не уникальный!') {
          console.error('Email не уникальный!')
          this.uniqEmail = false
          this.getErrors('email', this.$v.email)
          return
        }
        this.dialog = false
        await this.$store.dispatch('getOperators')
        this.$emit('data')
      }
      this.loader = false
    },
    async deleteOperator() {
      if (!this.getStoreOperators.corporations) return
      let payload = {
        user_id: this.getStoreOperators.id,
        corp_id: this.getStoreOperators.corporations.id
      }
      let res = await this.$store.dispatch('deleteOperator', payload)
      if (res && res.status === 200) {
        this.dialog = false
        await this.$store.dispatch('getOperators')
      } else console.error('ERROR IN deleteOperator')
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors
      switch (name) {
        case "operator":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "tel":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "email":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.email ? errors.push("Впишите email правильно!") :
                  !model.isUnique ? errors.push("Email не уникальный") : ""
          break;
        case "email-uniq":
          errors.push("Email не уникальный")
          break;
        case "password":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.minLength ? errors.push("Допускается не менее 6-ти символов!") : ""
          break;
        case "select":
          !model.required ? errors.push("Выбирите отдел!") : ""
          break;
        default:
          break;
      }
      return errors
    },
  }
}
</script>

<style scoped>

</style>
