<template>
  <div>
    <v-card-text>
      <v-card-title style="padding-top: 0" v-if="date">Дата регистрации: {{ getRegDate(date) }}</v-card-title>
      <v-data-table
          :headers="headers"
          :items="userStatus"
          :loading="loader"
          loading-text="Загрузка... Ожидайте!"
          style="cursor: pointer; padding-top: 0;"
      >
        <template v-slot:item.date="{item}">
          <b>{{ getDate(item.date) }}</b>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <div></div>
      <v-spacer></v-spacer>
      <v-btn
          color="blue darken-1"
          text
          @click="closeModal"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: 'product-status',
  props: {
    id: null,
    date: null
  },
  data() {
    return {
      headers: [
        {
          text: 'Статус',
          align: 'start',
          filterable: true,
          value: 'status',
          class: "success--text subtitle-2 font-weight-bold"
        },
        {text: 'Дата назначения статуса', value: 'date', class: "subtitle-2 font-weight-bold"},
      ],
      userStatus: [],
      loader: true
    }
  },
  async created() {
    if (this.id) {
      const user_status = await this.$store.dispatch('getUserStatus', {user_id: +this.id})
      if (user_status.length) this.userStatus= user_status
      this.loader = false
    }
  },
  destroyed() {
    this.userStatus = []
  },
  methods: {
    getDate(date) {
      return moment.unix(date).format('LLL')
    },
    getRegDate(date) {
      return moment.unix(date / 1000).format('LLL')
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
