<template>
  <div class="card__default">
    <h3>Изображения</h3>
    <v-file-input
        accept="image/*"
        label="Логотип"
    ></v-file-input>
    <v-file-input
        accept="image/*"
        label="Задний фон"
    ></v-file-input>
  </div>
</template>

<script>
export default {
  name: "Images"
}
</script>

<style scoped>

</style>