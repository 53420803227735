import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        users: [],
        moderationAllDocs: []
    }),
    mutations: {
        modalUserMutation(state, data) {
            state.modal = data || null
        },
        usersMutations(state, data) {
            state.users = data
        },
        moderationAllDocsMutation(state, data) {
            state.moderationAllDocs = data
        }
    },
    actions: {
        modalActionUser({commit}, data) {
            commit('modalUserMutation', data)
        },
        moderationAllDocsAction({commit}, data) {
          commit('moderationAllDocsMutation', data)
        },
        async getUsers({commit}) {
            let res = await axios('/admin-all-users', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                }
            })
            if (res && res.status === 200) {
                commit('usersMutations', res.data)
                return res.data
            }
            else console.error('ERROR IN getUsers')
        },
        updateUsers({}, payload) {
            return axios('/admin-update-user', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        updateUserType({}, payload) {
            return axios('/admin-update-user-type', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteDoc({}, payload) {
            try {
                return axios('/admin-delete-doc', {
                    method: 'POST',
                    headers: {
                        "Authorization": store.getters.getToken
                    },
                    data: payload
                })
            } catch (e) {
                console.error('ERROR IN deleteDoc', e)
            }
        },
        async getUserStatus({}, payload) {
            const res =  await axios('/get-user-status',  {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) return res.data
            else return [23]
        }
    },
    getters: {
        getUsersModal: state => state.modal,
        getUsersStatus: state => state.users,
        getModerationAllDocs: state => state.moderationAllDocs
    }
}
