import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null,
        initiators: []
    }),
    mutations: {
        modalMutationInitiator(state, data) {
            state.modal = data || null
        },
        initiatorsMutation(state, data) {
            state.initiators = data || []
        }
    },
    actions: {
        modalActionInitiator({commit}, data) {
            commit('modalMutationInitiator', data)
        },
        async getInitiators({commit}) {
            try {
                let res = await axios.post('/admin-all-initiators')
                if (res && res.status === 200) {
                    let initiators = res.data
                    if (initiators.length) initiators.map(item => item.show = true)
                    commit('initiatorsMutation', initiators)
                }
                else console.error('Не удалось получить данные от /admin-all-initiators')
            }
            catch (e) {
                console.error('ERROR IN getInitiators ', e)
            }
        },
        setInitiator({}, payload) {
            return axios('/admin-set-initiator', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        updateInitiator({}, payload) {
            return axios('/admin-update-initiator', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteInitiator({}, payload) {
            return axios('/admin-delete-initiator', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        }
    },
    getters: {
        getInitiatorsModal: state => state.modal,
        getInitiators: state => state.initiators
    }
}
