<template>
  <div class="card__default">
    <h3>Общие данные</h3>
    <div class="default__form__block">
      <v-text-field :disabled="adminStatus" label="Правовая форма" v-model="legalform"
                     :error-messages="getErrors('legalform', $v.legalform)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Название компании" v-model="inc" :error-messages="getErrors('inc',
       $v.inc)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="ОГРН компании" v-model="ogrn" :error-messages="getErrors('ogrn',
      $v.ogrn)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="ИНН компании" v-model="inn" :error-messages="getErrors('inn',
      $v.inn)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Адрес компании" v-model="address"
                    :error-messages="getErrors('address', $v.address)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Email" v-model="email" :error-messages="getErrors('email',
      $v.email)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Email тех. поддержки" v-model="support"
                    :error-messages="getErrors('support', $v.support)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Телефон" v-model="tel" v-mask="'+7 (###) ###-##-##'"
                    :error-messages="getErrors('tel', $v.tel)"></v-text-field>
      <v-text-field :disabled="adminStatus" label="Минимальное значение от старта торгов до окончания (в минутах!)"
                    v-model="tender_time" :error-messages="getErrors('tender_time', $v.tender_time)"></v-text-field>
      <v-text-field v-if="!adminStatus"
                    label="Ключ Dadata [ Обязательно! Указывайте слово Token и пробел перед ключом! ]"
                     v-model="dadataKey" :error-messages="getErrors('dadataKey', $v.dadataKey)"></v-text-field>
    </div>
    <v-row v-if="!adminStatus">
      <v-col>
        <v-btn
            depressed
            color="success"
            @click="save"
            :disabled="loader"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {required, email, integer, minValue} from "vuelidate/lib/validators"
export default {
  name: "Info",
  data() {
    return {
      inc: '',
      legalform: '',
      ogrn: '',
      inn: '',
      tel: '',
      email: '',
      support: '',
      address: '',
      loader: false,
      tender_time: 60,
      dadataKey: null,
    }
  },
  validations: {
    legalform: {required},
    inc: {required},
    ogrn: {required},
    inn: {required},
    address: {required},
    email: {required, email},
    support: {required, email},
    tel: {required},
    tender_time: {required, integer, minValue: minValue(1)},
    dadataKey: {required}
  },
  computed: {
    getOwnerStatus() {
      let ownerData = this.$store.getters.getOwner
      this.ownerBuild(ownerData)
      return ownerData
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getOwnerStatus(val) {
      this.ownerBuild(val)
    }
  },
  methods: {
    ownerBuild(val) {
      if(val.legalform) this.legalform = val.legalform
      if(val.inc) this.inc = val.inc
      if(val.ogrn) this.ogrn = val.ogrn
      if(val.inn) this.inn = val.inn
      if(val.address) this.address = val.address
      if(val.email) this.email = val.email
      if(val.support) this.support = val.support
      if(val.phone) this.tel = val.phone
      if(val.tender_time) this.tender_time = +val.tender_time
      if(val.dadata_key) this.dadataKey = val.dadata_key
    },
    async save() {
      if (this.$v.$invalid) {
        console.error('Error validation: ', this.$v)
        this.$v.$touch();
        return;
      }
      this.loader = true
      await this.$store.dispatch('updateOwner', {
        legalform: this.legalform,
        inc: this.inc,
        ogrn: this.ogrn,
        inn: this.inn,
        address: this.address,
        email: this.email,
        support: this.support,
        phone: this.tel,
        tender_time: +this.tender_time,
        dadata_key: this.dadataKey
      })
      this.loader = false
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors

      switch (name) {
        case "legalform":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "inc":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "ogrn":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "inn":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "address":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "email":
          !model.required ? errors.push("Поле не может быт пустым!") : !model.email ? errors.push("Впишите email правильно!") : ""
          break;
        case "support":
          !model.required ? errors.push("Поле не может быт пустым!") : !model.support ? errors.push("Впишите email тех. поддержки правильно!") : ""
          break;
        case "tel":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "tender_time":
          !model.required ? errors.push("Поле не может быт пустым!") : !model.integer ? errors.push("Допускаеются только цифры!") : !model.minValue ? errors.push("0 не допускается") : ""
        case "dadataKey":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        default:
          break;
      }
      return errors;
    },
  }
}
</script>

<style scoped>

</style>
