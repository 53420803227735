<template>
  <div class="card__default">
    <h3>Инициаторы торгов</h3>

    <div v-if="!adminStatus">
      <div class="mt-4" v-if="!addInput">
        <div>
          <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              @click="addInput=true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          Добавить инициатора
        </div>
        <v-divider class="mt-4" v-if="Initiators.length"></v-divider>
      </div>
      <div class="card__default mt-4" v-else>
        <v-text-field label="ФИО инициатора" v-model="addInitiator"></v-text-field>
        <div>
          <v-btn
              small
              color="secondary"
              dark
              @click="closeInput"
              class="mr-2"
          >
            Отменить
          </v-btn>
          <v-btn
              v-if="addInitiator"
              small
              color="error"
              dark
              @click="addInitiatorInDB"
          >
            Добавить
          </v-btn>
        </div>
      </div>
    </div>

    <v-list-item
        v-for="(item, i) in Initiators"
        :key="i"
    >
      <template v-if="item.show">
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="!adminStatus">
          <div>
            <v-icon class="icon__pencil mr-2" small @click="showUpdate(item.id)">mdi-pencil</v-icon>
          </div>
          <v-icon class="icon__delete" small @click="deleteInitiator(item.id)">mdi-delete</v-icon>
        </v-list-item-icon>
      </template>
      <v-text-field
          v-else
          outlined
          class="mt-4"
          label="ФИО инициатора"
          v-model="item.name"
          append-icon="mdi-check"
          append-outer-icon="mdi-close"
          @focus="storeName={id: item.id, name: item.name}"
          @click:append="updateInitiator(item.id, item.name)"
          @click:append-outer="closeInitiator(item.id)"
      ></v-text-field>
    </v-list-item>
    <Dialog/>
  </div>
</template>

<script>
import Dialog from '@/components/dialogs/initiator'
export default {
  name: "Initiator",
  components: {Dialog},
  data() {
    return {
      addInput: false,
      addInitiator: '',
      Initiators: [],
      storeName: null
    }
  },
  computed: {
    getStoreInitiator() {
      return this.$store.getters.getInitiatorsModal
    },
    getInitiatorsStatus() {
      let Initiators = this.$store.getters.getInitiators
      this.Initiators = Initiators
      return Initiators
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    getStoreInitiator() {
      this.getInitiators()
    },
    getInitiatorsStatus(val) {
      this.Initiators = val
    }
  },
  methods: {
    getInitiators() {
      this.$store.dispatch('getInitiators')
    },
    closeInput() {
      this.addInput = false
      this.addInitiator = ''
    },
    async addInitiatorInDB() {
      let res = await this.$store.dispatch('setInitiator', {name: this.addInitiator})
      if (res && res.status === 200) {
        await this.$store.dispatch('getInitiators')
        //this.Initiators.push({id: res.data.id, name: this.addInitiator, show: true})
      }
      else alert('Что пошло не так!')
      this.addInput = false
      this.addInitiator = ''
    },
    showUpdate(id) {
      this.Initiators.map(item => {
        if (item.id === id) item.show = false
        else item.show = true
        if (this.storeName && item.id === this.storeName.id) item.name = this.storeName.name
      })
      this.storeName = null
    },
    async updateInitiator(id, name) {
      if (!name) {
        alert('Вы не можете сохранить пустое значение "ФИО инициатора"')
        return
      }
      let res = await this.$store.dispatch('updateInitiator', {id, name})
      if (res && res.status === 200) await this.closeInitiator(id, true)
    },
    getThisInitiator(id) {
      return this.Initiators.find(item => item.id === id)
    },
    async closeInitiator(id, update=false) {
      let closeU = await this.getThisInitiator(id) //this.Initiators.find(item => item.id === id)
      if (closeU) {
        if (this.storeName && !update) closeU.name = this.storeName.name
        closeU.show = true
      }
      this.storeName = null
    },
    async deleteInitiator(id) {
      let Initiator = await this.getThisInitiator(id)
      await this.$store.dispatch('modalActionInitiator', {id, name: Initiator.name})
    }
  }
}
</script>

<style scoped>

</style>
